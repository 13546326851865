export default function RequestModal({children, ...props}) {


    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-black/60 pt-[150px] text-center overflow-y-auto z-[100]">
            <div className="relative w-full inline-block bg-white rounded-[16px_16px_8px_8px] text-start overflow-hidden max-w-[800px]">
                <div className={`${props?.bgColor} text-white h-[56px] p-[16px]`}>
                    <h2 className="text-[16px] font-[600]">{props?.heading}</h2>
                    <div className="w-[24px] h-[24px] absolute top-[16px] end-[14px] !bg-center !bg-no-repeat cursor-pointer select-none" style={{background:"url('/assets/images/close.svg')"}} onClick={()=>props?.handleCloseModal()}></div>
                </div>
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}