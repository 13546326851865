import CityConverter from "../../helpers/CityConverter";

export default function UnitCard({name, city, requests=0, onClick }) {

    let label;
    const words = name.trim().split(/\s+/).length;
    const letters = name.split(' ').map(function(item){return item[0]}).join('');;
    if (words < 2) {
        label = letters[0].toUpperCase();
    } else {
        label = letters[0].toUpperCase() + ' ' + letters[1].toUpperCase();
    }

    return (
        <div className="h-[87px] rounded-[8px] overflow-hidden bg-white cursor-pointer border-[1px] border-grey-300 relative" onClick={onClick}>
            <div className="inline-block align-middle w-[87px] h-[87px] overflow-hidden text-center border-l-[1px] border-l-grey-300"><span className={`w-full h-full text-[24px] font-[500] text-grey-900 block leading-[87px]`}>{label}</span></div>
            <div className="inline-block align-middle w-[200px] h-[87px] p-[12px]">
                <div className="whitespace-nowrap overflow-hidden truncate text-grey-900 text-[20px] font-[500] mb-[12px] leading-[30px]">{name}</div>
                <div className="w-full flex flex-nowrap items-center">
                    <div className="text-grey-600 text-[14px] font-[500]">
                        <div className="inline-block align-middle pe-[8px]">
                            <span className="inline-block align-middle w-[16px] h-[16px] me-[4px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/location.svg')", backgroundSize: "16px"}}></span>
                            {CityConverter(city)}
                        </div>
                        <div className="inline-block align-middle ps-[9px] relative">
                            <span className="block absolute start-0 h-[20px] top-[2px] w-[1px] bg-grey-300"></span>
                            <span className="inline-block align-middle w-[16px] h-[16px] me-[4px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/note.svg')", backgroundSize: "16px"}}></span>
                            {requests} طلب
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )

}