const GetRequestStyle = (status,type='label') => {

    let label;
    let heading;
    let color;
    let bgColor;

    switch (status) {
        case 'pending-operations':
            label = 'في انتظار قرار ادارة فني';
            heading = 'الطلب يحتاج الي مراجعه من ادارة فني';
            color = 'text-pandingOps';
            bgColor = 'bg-pandingOps';
            break;
        case 'pending':
            label = 'تنتظر التأكيد';
            heading = 'طلب يحتاج الي تاكيد';
            color = 'text-orange';
            bgColor = 'bg-orange';
            break;
        case 'done':
            label = 'مكتملة';
            heading = 'تم اكتمال الطلب';
            color = 'text-success';
            bgColor = 'bg-success';
            break;
        case 'active':
            label = 'تحت العمل';
            heading = 'الطلب تحت العمل';
            color = 'text-blue';
            bgColor = 'bg-blue';
            break;
        case 'canceled':
            label = 'ملغاة';
            heading = 'الطلب ملغي';
            color = 'text-danger';
            bgColor = 'bg-danger';
            break;
    }

    if(type === 'label') {
        return label;
    } else if (type === 'heading') {
        return heading;
    } else if (type === 'bg') {
        return bgColor;
    } else if (type === 'color') {
        return color;
    }


}

export default GetRequestStyle;