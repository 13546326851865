import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import apiClient from "../../components/utils/apiClient";
import MainContent from "../../containers/shared/MainContent";
import NormalFormContainer from "../../containers/shared/NormalFormContainer";
import InputField from "../../components/shared/InputField";
import SelectField from "../../components/shared/SelectField";
import ModalContainer from "../../containers/shared/ModalContainer";
import NormalButton from "../../components/shared/NormalButton";
import SingleImageUpload from "../../components/shared/SingleImageUpload";
import TextArea from "../../components/shared/TextArea";
import SingleImageCompress from "../../components/helpers/SingleImageCompress";
import {initializeApp, getApp, getApps} from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDownloadURL, uploadBytesResumable, ref } from "firebase/storage";
import { firebaseConfig } from "../../components/utils/constants";
import moment from "moment";
import Loading from "../../components/shared/Loading";
import Toast from "../../components/shared/Toast";



const PropertyAddPage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('إنشاء منشأة جديدة');
    const navigate = useNavigate();
    const pageTitle = {title: 'إنشاء منشأة جديدة', subTitle: 'يمكنك إنشاء منشأتك من هنا'};
    const [addPropertyForm,setAddPropertyForm] = useState({name: '', description: '', files:[]});
    const [errors, setErrors] = useState(null);
    const [roleInfoModal, setRoleInfoModal] = useState(false);
    const [isAddPropertyFormDisabled, setIsAddPropertyFormDisabled] = useState(true);
    const [uploadPreview, setUploadPreview] = useState(null);
    const [compressedImage, setCompressedImage] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [PropertyId, setPropertyId] = useState(null);
    const [toast, setToast] = useState({show: false,type: null, message:'' });
  
    useEffect(()=>{
      if(addPropertyForm && addPropertyForm?.name) {
        setIsAddPropertyFormDisabled(false);
      } else {
        setIsAddPropertyFormDisabled(true);
      }
    },[addPropertyForm]);
  
    const hideError = (err) => {
      if(errors) {
        let errorsList = errors;
        delete errorsList[err];
        setErrors(errorsList);
      }
    }


    const handleCloseModal = () => {
        setRoleInfoModal(false);
    }



    function handleChangeUpload(e) {
        SingleImageCompress(e.target.files[0]).then(compressedFile=>setCompressedImage(compressedFile));
        setUploadPreview(URL.createObjectURL(e.target.files[0]));
    }


    const handleSubmitAddProperty = () => {
        setShowLoading(true);
        setIsAddPropertyFormDisabled(true);
        if(compressedImage) {
            const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
            const storage = getStorage(app);
            const file = compressedImage;
            const time = Date.parse(moment().locale('en').format('YYYY-MM-DD HH:mm:ss')) / 1000;
            const storageRef = ref(storage,'fanni-business/propertyImages/'+time+'_'+file.name);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                (error) => {
                    setShowLoading(false);
                    setToast({show:true,type:'error',message:'حدث خطأ في رفع الصورة ,من فضلك حاول مرة اخري في وقت لاحق'});
                },
                () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
                    handleSubmitAddPropertyAction(downloadedURL);
                });
                }
            );
        } else {
            handleSubmitAddPropertyAction();
        }
    }

    const handleSubmitAddPropertyAction = async (ImageUrl=null) => {
        setErrors(null);
        const finalAddPropertyForm = addPropertyForm;
        if(ImageUrl) {
            finalAddPropertyForm.files = [ImageUrl];
        }
        try {
        const response = await apiClient.post(`/b-properties`,finalAddPropertyForm);
        if(response?.data) {
            setShowLoading(false);
            setPropertyId(response?.data?._id);
            setSuccessMessage(true);
        } else {
            setIsAddPropertyFormDisabled(false);
            setShowLoading(false);
        }
        } catch(err) {
            setIsAddPropertyFormDisabled(false);
            setShowLoading(false);
            if(err?.response?.data?.errors) {
                let errorsList = {};
                err?.response?.data?.errors?.map(err=>{
                    errorsList[err.field] = err.message; 
                });
                setErrors(errorsList);
            }
        }
    }
    

    const handleClickPropertyDetails = () => {
        navigate(`/ar/property/${PropertyId}`);
      }

    


  return (
    <>
        <div>
        <PageHeader pageTitle={pageTitle} />
        <PageMain>
            <MainContent>
                <NormalFormContainer heading="من فضلك قم بملئ البيانات التالية لإنشاء منشأة جديدة." label="اضف منشأة جديدة">
                    
                    <div className="mb-[15px]">
                        <SingleImageUpload onchange={handleChangeUpload} preview={uploadPreview} label={'صورة المنشأة (اختياري)'} subLabel={'تساعد صورة المنشأة على تمييز منشأتك'} info={'تسمح برفع png, jpg, jpeg'} />
                    </div>

                    <div className="mb-[15px]">
                        <InputField label={'اسم المنشأة (إجباري)'} placeholder={'اكتب الاسم'} value={addPropertyForm?.name} error={errors?.name} errorMessage={errors?.name} translateError={true} onChange={(e)=>{setAddPropertyForm({ ...addPropertyForm, name: e.target.value }); hideError('name')}} />
                    </div>

                    <div className="mb-[15px]">
                        <TextArea label={'وصف المنشأة (إجباري)'} placeholder={'مثال لنص يمكن استخدامة كوصف للمنشأة'} value={addPropertyForm?.description} error={errors?.description} errorMessage={errors?.description} translateError={true} onChange={(e)=>{ setAddPropertyForm({ ...addPropertyForm, description: e.target.value }); hideError('description')}} />
                    </div>

                    <div className="text-end">
                        <NormalButton label={'إنشاء منشأة'} onClick={handleSubmitAddProperty} isDisabled={isAddPropertyFormDisabled} />
                    </div>

                </NormalFormContainer>
            </MainContent>
        </PageMain>
        </div>
        { successMessage && <ModalContainer hideX={true} maxWidth={'600px'} handleCloseModal={handleCloseModal}>
            <div className="text-center mb-[16px]">
                <span className="block w-[128px] h-[128px] m-auto !bg-center !bg-no-repeat" style={{background:"url('/assets/images/success.svg')"}} ></span>
            </div>
            <div className="text-center text-grey-900 mb-[80px]">
                <p className="font-[700] text-[30px] leading-[185%]">تهانينا !</p>
                <p className="font-[500] text-[24px] leading-[185%]">لقد قمت بإنشاء منشأتك بنجاح.</p>
            </div>
            <div className="text-center bg-grey-100 absolute bottom-0 left-0 w-full h-[96px] pt-[24px]">
                <NormalButton label={'الذهاب الي تفاصيل المنشأة'} onClick={handleClickPropertyDetails} color={'!bg-grey-800'} />
            </div>
        </ModalContainer> }
        <Toast toast={toast} setToast={setToast} />
        <Loading showLoading={showLoading} />
    </>
  );
};

export default PropertyAddPage;
