import { useNavigate } from "react-router-dom";

export default function UsersBar({ usersCount=0, searchPlaceholder="ابحث باسم المستخدم..." , searchInput, ButtonLabel="اضف مستخدم جديد", ButtonLink="/ar/users/add" }) {

    const navigate = useNavigate();
    const handleClickButton = () => {
        navigate(`/ar/users/add`);
    }
    return (
        <div className="rounded-[8px] bg-white h-[56px] flex flex-nowrap">
             <div className="leading-[50px] h-[50px] text-grey-900 font-[500] text-[20px] ps-[10px] pe-[25px] mt-[3px] border-e-[1px] border-e-grey-200 whitespace-nowrap">
                كل المستخدمين 
                <span className="text-orange"> {`(${usersCount})`}</span>
             </div>
             <div className="flex-auto relative ps-[25px] pe-[35px]">
                <input type="text" className="outline-none leading-[50px] h-[50px] mt-[3px] w-full text-[18px] text-grey-600 font-[400]" placeholder={searchPlaceholder} />
                <span className="absolute end-[35px] top-[16px] w-[24px] h-[24px] select-none cursor-pointer !bg-center !bg-no-repeat" style={{background:"url('/assets/images/search-icon.svg')"}}></span>
             </div>
             <div className="w-[258px] rounded-e-[8px] bg-blue cursor-pointer text-center text-white leading-[56px] text-[18px] font-[700]" onClick={()=>handleClickButton()}>
                {ButtonLabel}
             </div>
        </div>
    )

}