import { useEffect, useState } from "react";
import SelectBoxContainer from "../../../containers/shared/SelectBoxContainer";
import apiClient from "../../utils/apiClient";
import UnitSelectCard from "./UnitSelectCard";

export default function SelectUnitsBox({label, allText, propertyId, unitsCount, setTotalSelectedUnits, topAllUnits, index}) {

    const [selectStatus, setSelectStatus] = useState(false);
    const [count, setCount] = useState(unitsCount)
    const [units, setUnits] = useState(null);
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [allUnits, setAllUnits] = useState(null);

    useEffect(()=>{
        if(propertyId) {
            getUnits(propertyId);
        }
    },[propertyId]);

    useEffect(()=>{
        if(units && Array.isArray(units) && units?.length) {
            setAllUnits(units?.map(unit=> unit?._id));
        }
    },[units]);

    useEffect(()=>{
        if(JSON.stringify(selectedUnits?.sort()) === JSON.stringify(allUnits?.sort())) {
            setSelectStatus(true);
        } else {
            setSelectStatus(false);
        }
        setTotalSelectedUnits(units => [...units.slice(0,index), getSelectedObjects(selectedUnits), ...units.slice(index+1)]);
    },[selectedUnits]);

    useEffect(()=>{
        if(topAllUnits === 'all') {
            handleClickAllButton();
        }
        if(topAllUnits === 'none') {
            handleClickAllReverseButton();
        }
    },[topAllUnits])

    const getUnits = async (propertyId) => {
        try {
            const response = await apiClient.get(`/b-properties/${propertyId}`);
            if(response?.data) {
                setUnits(response?.data?.units);
                } else {
        
                }
            } catch(err) {}
    }

    const getSelectedObjects = (units) => {
        return units?.map(unit=> { 
            return { "unitId": unit,
            "propertyId": propertyId,
            "serviceDetails": {
              "serviceCode": '',
              "subServiceCode": ''
            },
            "startWorkingDate": ''}
        })
    }


    const handleClickAllButton = () => {
        setSelectedUnits(allUnits);
    }

    const handleClickAllReverseButton = () => {
        setSelectedUnits([]);
    }


    return (
        units && <SelectBoxContainer label={label} selectStatus={selectStatus} count={count} handleClickAllButton={selectStatus ? handleClickAllReverseButton : handleClickAllButton} allText={allText}>
            {units?.map((unit,index)=>{
                return (<UnitSelectCard key={`selectUnit-${index}`} unit={unit} selectedUnits={selectedUnits} setSelectedUnits={setSelectedUnits} />)
            })}
        </SelectBoxContainer>
    )

}