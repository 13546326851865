import { useEffect, useState } from "react";
import useUser from "../helpers/useUser"
import apiClient from "../utils/apiClient";
import { Link,useNavigate } from "react-router-dom";

export default function PageHeader({pageTitle,userCallback,isColored=false,subTitleSize='normal',blueTitle=false}) {

    const navigate = useNavigate();
    const [user,setUser] = useUser();
    const [userData, setUserData] = useState(user);
    const [userMenu, setUserMenu] = useState(false);

    const userId = user ? user?._id : null;

    
    const getUserData = async () => {
        let url;
        if(userData?.role === 'OWNER') {
            url = '/b-owners/';
        } else {
            url = '/b-user/';
        }
        try {
          const response = await apiClient.get(`${url}${userId}`);
          if(response?.data) {
            setUserData(response?.data);
            userCallback(response.data);
          } else {
  
          }
        } catch(err) {}
      }

    useEffect(()=>{
        if(user) {
            getUserData();
        }
    },[])

    let role;
    if(userData?.role?.id) {
        switch(userData?.role?.id) {
            case 'OWNER' :
                role='مالك الحساب';
            break;
            case 'ADMINISTRATOR':
                role='المدير';
            break;
            default:
                role='الموظف';
        }
    } else {
        switch(userData?.role) {
            case 'OWNER' :
                role='مالك الحساب';
            break;
            case 'ADMINISTRATOR':
                role='المدير';
            break;
            default:
                role='الموظف';
        }
    }

    const handleClickSettings = () => {
        navigate(`/ar/settings`);
    }
    
    const handleClickLogout = () => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('b-user');
        window.location.reload();
    }
    
    

    return (
        (userData && userData !== undefined) ? <header className={`flex h-[120px] relative ${isColored ? '!bg-grey-50' : ''}`}>
          <div className="w-[50%] pt-[25px] ps-[25px] relative">
            <h2 className={`text-[24px] ${blueTitle ? 'text-blue' : 'text-grey-900'} font-[700] mb-[5px] ${pageTitle?.subTitle ? 'leading-[36px]' : 'leading-[56px]'}`}>{pageTitle && pageTitle?.title}</h2>
            {pageTitle?.subTitle && <p className={`${subTitleSize === 'normal' && 'text-[13px] leading-[20px]'} ${subTitleSize === 'big' && 'text-[24px] leading-[27px]'} text-grey-950 font-[500] whitespace-nowrap truncate overflow-hidden`}>{pageTitle && pageTitle?.subTitle}</p>}
          </div>
          <div className="w-[50%] text-end pt-[32px] pe-[25px]">
            <div className="align-middle cursor-pointer inline-block border-[1px] border-grey-100 rounded-[8px] h-[56px] pt-[5px] me-[15px] bg-white">
                <span className="inline-block align-middle w-[50px] h-[32px] !bg-center !bg-no-repeat border-e-[1px] border-grey-100" style={{background:"url('/assets/images/wallet-icon.svg')"}}></span>
                <Link to="/ar/wallet" className="inline-block align-middle relative px-[15px] select-none">
                    <p className="text-[12px] font-[500] leading-[18px] text-grey-600 mb-[3px]">رصيد المحفظة</p>
                    <p className="text-[18px] font-[600] leading-[18px] text-grey-900 lang-font" dir="ltr">{userData?.business?.credits} <small className="text-[12px] font-[500] lang-font">sr</small></p>
                </Link>
            </div>
            <div className="align-middle cursor-pointer inline-block border-[1px] border-grey-100 rounded-[8px] h-[56px] pt-[3px] me-[15px] relative bg-white select-none" onClick={()=>setUserMenu(!userMenu)}>
                <span className="inline-block align-middle w-[60px] h-[48px] !bg-center !bg-no-repeat rounded-full overflow-hidden" style={{background:userData.avatar ? "url('"+userData?.avatar+"')" : "url('/assets/images/profile.svg')"}}></span>
                <div className="inline-block align-middle relative pe-[40px]">
                    <p className="text-[18px] font-[500] leading-[27px] text-grey-900 mb-[0px]">{userData?.name}</p>
                    <p className="text-[14px] font-[500] leading-[18px] text-grey-600 " dir="ltr">{role}</p>
                </div>
                <span className="block absolute top-[16px] end-[5px] w-[32px] h-[32px]" style={{background:"url('/assets/images/arrow-down.svg')"}}></span>
            </div> 

            {userMenu && <div className="absolute top-[87px] left-[92px] w-[200px] h-[115px] rounded-b-[8px] bg-white border shadow-[0_0_5px_0_rgba(0,0,0,5%)] border-menuBorder z-[3]">
                <ul className="text-[16px] font-[500] text-start py-[8px] px-[12px]">
                    <li className="text-grey-600 cursor-pointer border-b border-b-grey-200 leading-[50px] px-[12px]" onClick={handleClickSettings}>اعدادات الحساب</li>
                    <li className="text-danger cursor-pointer leading-[48px] px-[12px]" onClick={handleClickLogout}>تسجيل الخروج</li>
                </ul>
            </div>}

            <div className="align-middle cursor-pointer inline-block border-[1px] border-grey-100 rounded-[8px] h-[56px] pt-[10px] bg-white">
                <span className="w-[50px] h-[32px] block !bg-center !bg-no-repeat" style={{background:"url('/assets/images/notification-icon.svg')"}}></span>
            </div>
          </div>
        </header>
        : <header></header>
    )

}