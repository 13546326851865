import { SERVICES } from "../utils/constants";

const GetServiceByCode = (field,code) => {
    let catchCode;
    let catchType;
    const getObject = SERVICES[SERVICES?.findIndex((item) => item?.field?.code === field)];
    if(getObject !== undefined) {
        getObject?.maintenanceListPerDevice?.map((Obj) => {
            Obj?.maintenanceList?.map((item) => {
                console.log(item?.code, code);
                if(item?.code === code) {
                    catchCode = item?.name;
                    catchType = Obj?.device?.name;
                }
            });
        });
    } else {
        return null;
    }
    console.log(catchType,catchCode);
    return [catchType,catchCode];
}

export default GetServiceByCode;