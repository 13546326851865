import axios from 'axios';

const base_url = process.env.REACT_APP_API_URL;

 const apiClient = axios.create({
    baseURL: base_url,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'x-User-Agent': 'dashboard',
        'Accept-Language': 'ar',
    }
  });

  apiClient.interceptors.request.use(
    (config) => {
      const token = window?.localStorage?.getItem('token') || false;
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`; 
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );


  apiClient.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {

        
        if ( (err.config.url !== '/b-owners/register' && err.config.url !== '/b-users/one-time-password-login' && err.config.url !== '/b-users/login') && err.response) {
           if (err?.response?.status === 401) {
            if(err?.response?.data?.category === "UNAUTHORIZED") {
              localStorage.removeItem('user');
              localStorage.removeItem('token');
              window.location.href = `/ar/login`; 
            }
                // toast('يجب عليك تسجيل الدخول مرة اخرى', { hideProgressBar: true, autoClose: 2000, type: 'error' })
                // deleteCookie('token');
                // localStorage.removeItem('user');
                // localStorage.removeItem('token');
                // window.location.href = `/ar/login`; 
                return;
            } else {
                if(err?.response?.data?.message) {
                    // toast(err.response.data.message, { hideProgressBar: true, autoClose: 2000, type: 'error' });
                    // const errorsList = err?.response?.data?.errors;
                    // if(errorsList) {
                    //   Array.isArray(errorsList) 
                    //   ? errorsList.map(err=>{
                    //     err.message && toast(err.field+' '+err.message, { hideProgressBar: true, autoClose: 2000, type: 'error' });
                    //   })
                    //   : errorsList.message && toast(errorsList.message, { hideProgressBar: true, autoClose: 2000, type: 'error' });
                    // }
                } else {
                    // toast('حدث خطأ, برجاء المحاولة لاحقا', { hideProgressBar: true, autoClose: 2000, type: 'error' })
                }
            }
        }
  
      return Promise.reject(err);
    }
  );

  export default apiClient;