// useDocumentTitle.js
import { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);
  const {t, i18n} = useTranslation();

  useEffect(() => {
    document.title = `${t(title)} | ${t("appName")}`;
  }, [title]);

  i18n.on('languageChanged', lang => {
    document.title = `${t(title)} | ${t("appName")}`;
  });

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}

export default useDocumentTitle