import { useEffect, useState } from "react";

export default function useUser(key='b-user') {
    const [user, setUser] = useState(() => {
      try {
         const storedUser = window.localStorage.getItem(key);
         const getUser = storedUser && storedUser !== undefined && storedUser !== 'undefined' ? storedUser : undefined;
         return JSON.parse(getUser);
      } catch (error) {
        console.error(error);
        return undefined;
      }
    });
  
    useEffect(() => {
      try {
        if(user !== 'remove') {
            window.localStorage.setItem(key, JSON.stringify(user));
        } else {
            window.localStorage.removeItem(key);
        }
      } catch (error) {
        console.error(error);
      }
    }, [key, user]);
  
    return [user, setUser];
  }