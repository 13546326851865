import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import apiClient from "../../components/utils/apiClient";
import MainContent from "../../containers/shared/MainContent";
import UsersBar from "../../components/users/UsersBar";
import UsersList from "../../containers/users/UsersList";
import UserCard from "../../components/users/UserCard";
import ModalContainer from "../../containers/shared/ModalContainer";
import NormalButton from "../../components/shared/NormalButton";

const UsersHomePage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('المستخدمين');
    const navigate = useNavigate();
    const pageTitle = {title: 'المستخدمين', subTitle: 'يمكنك اضافة وادارة مستخدمين النظام.'};
    const [users,setUsers] = useState(null);
    const [ownersList,setOwnersList] = useState(null);
    const [adminsList,setAdminsList] = useState(null);
    const [employeesList, setEmployeesList] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userDataModal, setUserDataModal] = useState(false);

    const getUsers = async () => {
      try {
        const response = await apiClient.get(`/b-users`);
        if(response?.data) {
          setUsers(response?.data);
        } else {

        }
      } catch(err) {}
    }

    const splitUsers = () => {
      let owners = users?.filter(user => user?.role?.id === 'OWNER');
      setOwnersList(owners);

      let admins = users?.filter(user => user?.role?.id === 'ADMINISTRATOR');
      setAdminsList(admins);

      let employees = users?.filter(user => user?.role?.id === 'EMPLOYEE');
      setEmployeesList(employees);
    }

    const handleClickUserCard = (id) => {
      setSelectedUserId(id);
      let selectedUserObject = users?.filter(user=> user?._id === id)[0];
      setSelectedUser(selectedUserObject);
      setUserDataModal(true);
    }

    const handleCloseModal = () => {
      setUserDataModal(false);
    }

    const handleClickEditUser = () => {
      navigate(`/ar/users/edit/${selectedUserId}`);
    }

    useEffect(()=>{
      getUsers();
    },[]);

    useEffect(()=>{
      if(users) {
        splitUsers();
      }
    },[users])

  return (
    <div>
      <PageHeader pageTitle={pageTitle} />
      <PageMain>
        <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
        <MainContent>
          <UsersBar usersCount={users?.length} />
          {/* no users view */}
          {users !== null && Array.isArray(users) && users?.length < 1 && 
            <div className="pt-[80px] text-center">
              <div className="inline-block w-[188px] h-[188px] !bg-contain !bg-center" style={{background: "url('/assets/images/main-user-icon.svg')"}}></div>
              <div className="w-full text-center text-grey-900 text-[24px] font-[500] mb-[25px]">لم تقم بإضافة اي مستخدم حتي الأن</div>
              <Link className="inline-block min-w-[320px] h-[48px] leading-[48px] rounded-[8px] text-white bg-grey-800 text-[18px] font-[600]" to="/ar/users/add">اضف مستخدم جديد</Link>
            </div>
          }
          {/* users view */}
          {users !== null && Array.isArray(users) && users?.length > 0 && 
            <div className="pt-[25px]">
              {ownersList?.length > 0 && <UsersList label={'مالك الحساب'} usersCount={ownersList?.length}>
                {ownersList?.map((user,index)=>{
                  return(<UserCard key={'ownerUser-'+index} name={user?.name} units={'مالك الحساب'} onClick={()=>handleClickUserCard(user?._id)}  />);
                })}
              </UsersList>}

              {adminsList?.length > 0 && <UsersList label={'المديرين'} usersCount={adminsList?.length}>
                {adminsList?.map((user,index)=>{
                  return(<UserCard key={'adminUser-'+index} name={user?.name} units={'المدير'}  onClick={()=>handleClickUserCard(user?._id)} />);
                })}
              </UsersList>}

              {employeesList?.length > 0 && <UsersList label={'الموظفين'} usersCount={employeesList?.length}>
                {employeesList?.map((user,index)=>{
                  return(<UserCard key={'employeeUser-'+index} name={user?.name} units={'الموظف'}  onClick={()=>handleClickUserCard(user?._id)} />);
                })}
              </UsersList>}
            
            </div>
          }

        </MainContent>
      </PageMain>

      { userDataModal && <ModalContainer heading="بيانات المستخدم" handleCloseModal={handleCloseModal}>
            <div>
              <label className="text-[12px] text-grey-900 font-[700] leading-[18px] mb-[3px]">الاسم بالكامل</label>
              <p className="text-[16px] leading-[24px] pb-[15px] mb-[15px] font-[500] text-grey-600 border-b-[1px] border-b-grey-200">{selectedUser?.name}</p>
            </div>
            <div>
              <label className="text-[12px] text-grey-900 font-[700] leading-[18px] mb-[3px]">رقم الهاتف</label>
              <p className="text-[16px] leading-[24px] pb-[15px] mb-[15px] font-[500] text-grey-600 border-b-[1px] border-b-grey-200 text-right" dir="ltr">{selectedUser?.mobile}</p>
            </div>
            <div>
              <label className="text-[12px] text-grey-900 font-[700] leading-[18px] mb-[3px]">الايميل</label>
              <p className="text-[16px] leading-[24px] pb-[15px] mb-[15px] font-[500] text-grey-600 border-b-[1px] border-b-grey-200">{selectedUser?.email}</p>
            </div>
            <div>
              <label className="text-[12px] text-grey-900 font-[700] leading-[18px] mb-[3px]">دور المستخدم</label>
              <p className="text-[16px] leading-[24px] pb-[15px] mb-[15px] font-[500] text-grey-600 border-b-[1px] border-b-grey-200">{selectedUser?.role?.name}</p>
            </div>
            <div>
              <label className="text-[12px] text-grey-900 font-[700] leading-[18px] mb-[3px]">الوحدات المعينة للمستخدم</label>
              <p className="text-[16px] leading-[24px] pb-[15px] mb-[15px] font-[500] text-grey-600">{(selectedUser?.units && Array.isArray(selectedUser?.units) && selectedUser?.units?.length > 0) ? selectedUser?.units?.map((unit,index)=><div className="inline-block me-[3px] align-middle"><span hidden={index === 0}> - </span><span key={unit}>{unit?.name}</span></div>) : 'لاتوجد وحدات'}</p>
            </div>
            <div className="text-end">
              <div className="text-grey-400 text-[18px] font-[600] inline-block cursor-pointer select-none me-[50px]" onClick={handleCloseModal}>إلغاء</div>
              <NormalButton label={'تعديل حساب المستخدم'} onClick={handleClickEditUser} />
            </div>
        </ModalContainer> }

    </div>
  );
};

export default UsersHomePage;
