import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import MainContent from "../../containers/shared/MainContent";
import RequestsBar from "../../components/requests/RequestsBar";
import apiClient from "../../components/utils/apiClient";
import RequestsTable from "../../components/requests/RequestsTable";
import useUser from "../../components/helpers/useUser";

const RequestsHomePage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('الطلبات');
    const pageTitle = {title: 'الطلبات', subTitle: ''};
    const [unitsIds, setUnitsIds] = useState(null);
    const [requests, setRequests] = useState(null);
    const [user,setUser] = useUser();


    

    const getRequests = async () => {
      try {
        const response = await apiClient.get(`/b-business-requests/?business=${user?.business}`);
        if(response?.data) {
          setRequests(response?.data?.requests);
        } else {

        }
      } catch(err) {}
    }


    useEffect(()=>{
      if(user?.business) {
        getRequests();
      }
    },[user])

    
    useEffect(()=>{
      if(unitsIds && Array.isArray(unitsIds) && unitsIds?.length > 0) {
        const getRequests = async () => {
          const bulkRequests = await Promise.all(unitsIds.map(async (id) => (
            await apiClient.get(`/b-units/${id}`).then(r=> r?.data?.subRequests)
          )));
          
          if(Array.isArray(bulkRequests) && bulkRequests?.length > 0) {
            setRequests(FormatRequests(bulkRequests));
          } else {
            setRequests([]);
          }
        };
        getRequests();
      } 
      if(unitsIds && Array.isArray(unitsIds) && unitsIds?.length === 0) {
        setRequests([]);
      }
    },[unitsIds]);
    
    const FormatRequests = (requests) => {
      return requests?.flat(1)?.filter((obj1, i, arr) => 
        arr.findIndex(obj2 => (obj2._id === obj1._id)) === i
    )
  }
  

  
  return (
    <div>
      <PageHeader pageTitle={pageTitle} isColored />
      <PageMain>
        <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
        <MainContent>
          <RequestsBar requestsCount={requests?.length} />
          {requests ? Array.isArray(requests) && requests?.length > 0 
          ? <RequestsTable requests={requests} type="main" />
          : <div className="pt-[100px] text-center">
              <div className="inline-block w-[188px] h-[188px] !bg-contain !bg-center" style={{background: "url('/assets/images/note.svg')"}}></div>
              <div className="w-full text-center text-grey-900 text-[24px] font-[500] mb-[25px]">لم تقم باي طلبات الي الان</div>
              <Link className="inline-block min-w-[320px] h-[48px] leading-[48px] rounded-[8px] text-white bg-grey-800 text-[18px] font-[600]" to={'/ar/requests/add'}>إنشاء طلب جديد</Link>
          </div> : <></> }
        </MainContent>
      </PageMain>
    </div>
  );
};

export default RequestsHomePage;
