export default function NormalBoxContainer({children, ...props}) {
        
    return (
        <div className="px-[15px]">
        <div className="relative">
            {props?.leftedLabel 
            ? <div className="flex flex-nowrap">
                <div className="w-[50%] h-[56px] leading-[56px] bg-grey-100 text-grey-900 font-[600] text-[16px] px-[16px] rounded-t-[8px]">{props?.label}</div>
                <div className="w-[50%] h-[56px] leading-[56px] bg-grey-100 text-blue font-[500] text-[14px] px-[16px] rounded-t-[8px] text-end">{props?.leftedLabel}</div>
              </div>
            : <div className="h-[56px] leading-[56px] bg-grey-100 text-grey-900 font-[600] text-[16px] px-[16px] rounded-t-[8px]">{props?.label}</div>}
            <div className="p-[24px] rounded-b-[8px] border-[1px] border-grey-200 border-t-0 bg-white">
                {children}
            </div>
        </div>
        </div>
    )
}