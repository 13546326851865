export default function UnitSelectCard({unit,selectedUnits,setSelectedUnits}) {
        let status;
        if(selectedUnits?.includes(unit?._id)) {
            status = true;
        } else {
            status = false;
        }

        const handleClicAddUnitToSelected = (id) => {
            setSelectedUnits(selectedUnits => [...selectedUnits, id])
        }
        const handleClickRemoveUnitFromSelected = (id) => {
            setSelectedUnits(selectedUnits?.filter(unit=> unit != id));
        }


        return (
            <div className={`w-[25%] rounded-[8px] p-[8px_12px] cursor-pointer relative border-[1px] ${status ? 'bg-selectedcard border-blue' : 'bg-grey-100 border-[transparent]'}`} onClick={()=> status ? handleClickRemoveUnitFromSelected(unit?._id) : handleClicAddUnitToSelected(unit?._id)}>
                {status && <span className="w-[20px] h-[20px] block align-middle !bg-center !bg-no-repeat top-[8px] end-[12px] absolute" style={{background: "url('/assets/images/check-mark.svg')"}}></span>}
                <p className={`font-[600] leading-[30px] text-[20px] pb-[5px] ${status ? 'text-blue' : 'text-grey-900'}`}>{unit?.name}</p>
                <div className={`inline-block align-middle me-[8px] leading-[21px] font-[500] text-[14px] ${status ? 'text-blue' : 'text-grey-600'}`}><span className={`inline-block w-[16px] h-[16px] align-middle me-[5px] !bg-center !bg-no-repeat mt-[-5px]`} style={{background: `url('/assets/images/${status ? 'area-colored.svg' : 'area.svg'}')`}}></span>{unit?.area}</div>
                <div className={`inline-block align-middle me-[8px] leading-[21px] font-[500] text-[14px] ${status ? 'text-blue' : 'text-grey-600'}`}><span className={`inline-block w-[16px] h-[16px] align-middle me-[5px] !bg-center !bg-no-repeat mt-[-5px]`} style={{background: `url('/assets/images/${status ? 'rooms-colored.svg' : 'rooms.svg'}')`}}></span>{unit?.numOfRooms}</div>
            </div>
        )

}