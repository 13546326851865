import React from "react";
import { Link } from "react-router-dom";
import LangSwithcher from "../../components/helpers/LangSwithcher";
import { useTranslation } from "react-i18next";


const RegisterSuccess = () => {
    const {t,i18n} = useTranslation();
    return (
        <>
        <div className="absolute w-[calc(100%-48px)] top-[24px] start-[24px] flex flex-nowrap">
            <div className="w-[50%] h-[47px]">
              <Link to="/"><img src="/assets/images/logo-normal.svg" className="w-[128px] h-[47px]" alt="" /></Link>
            </div>
            <div className="w-[50%] h-[47px] text-end">
              {/* <LangSwithcher style="auth" /> */}
            </div>
          </div>
          <div className="pt-[342px] pb-[50px] text-center">
            <div className="rounded-[16px] bg-grey-200 mx-auto my-[15px] w-[calc(100%-30px)] max-w-[911px] p-[5px] text-center">
                <img src="/assets/images/success.svg" alt="" className="inline-block mt-[30px] mb-[20px]" />
                <h3 className="text-grey-900 text-[40px] font-[600] leading-[74px] mb-0">{t('registerSuccess.congratulations')}</h3>
                <p className="text-grey-900 text-[28px] font-[500] leading-[51px] mb-[15px]">{t('registerSuccess.message')}</p>
                <p className="text-grey-600 text-[24px] font-[500] leading-[36px] mb-[35px]">{t('registerSuccess.soon')}</p>
            </div>
          </div>
        </>
    )
}

export default RegisterSuccess;
