export default function UsersList({children, ...props}) {
    
    const label = props?.label || 'قائمة المستخدمين';
    
    return (
        <div className="mt-[25px]">
            <div className="w-full">
                <div className="h-[43px] leading-[43px] bg-grey-100 text-grey-900 font-[600] text-[18px] px-[16px] rounded-t-[8px]">
                    {label}
                    <span className="text-orange"> {`(${props?.usersCount})`}</span>
                </div>
                <div className="p-[24px] rounded-b-[8px] border-[1px] border-grey-200 border-t-0 flex flex-wrap gap-[25px]">
                    {children}
                </div>
            </div>
        </div>
    )
}