import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; 
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import MainContent from "../../containers/shared/MainContent";
import { useParams } from "react-router-dom";
import apiClient from "../../components/utils/apiClient";
import GetRequestStyle from "../../components/helpers/GetRequestStyle";
import { FIELDS } from "../../components/utils/constants";
import GetServiceByCode from "../../components/helpers/GetServiceByCode";

const SingleRequestPage = () => {
    const {t,i18n} = useTranslation();
    const { id } = useParams();
    const [singleRequest, setSingleRequest] = useState(null);
    useDocumentTitle(singleRequest ? `طلب رقم ${singleRequest?.prettyId}` : 'الطلبات');
    const pageTitle = {title: singleRequest ? `طلب رقم ${singleRequest?.prettyId}` : 'الطلبات', subTitle: ''};
    const [propertiesAndUnits, setPropertiesAndUnits] = useState(null);
    const [propertiesAndUnitsText, setPropertiesAndUnitsText] = useState(null);

    
    const getSingleRequest = async (singleId) => {
        try {
            const response = await apiClient.get(`/b-business-requests/${id}`);
            if(response?.data) {
                setSingleRequest(response?.data);
                let propertyNames = [];
                let unitsNames = [];
                if(response?.data?.subRequests && 'subRequests' in response?.data) {
                    response?.data?.subRequests?.map((subRequest, index) => {
                        propertyNames = [...propertyNames, subRequest?.propertyName];
                        unitsNames = [...unitsNames, subRequest?.unitName];
                    });
                } else {
                    response?.data?.units?.map((unit, index) => {
                        propertyNames = [...propertyNames, unit?.propertyName];
                        unitsNames = [...unitsNames, unit?.unitName];
                    });
                }
                setPropertiesAndUnits([propertyNames, unitsNames]);

            } else {

            }
        } catch(err) {}
    }

    useEffect(()=>{
        getSingleRequest();
    },[])

    useEffect(()=>{
        if(propertiesAndUnits && Array.isArray(propertiesAndUnits) && propertiesAndUnits?.length > 0) {
            let propertiesText;
            let unitsText;
            
            [...new Set(propertiesAndUnits[0])].map((p,i)=> i===0 ? propertiesText = ''+p : propertiesText += ' + ' + p);
            [...new Set(propertiesAndUnits[1])].map((u,i)=> i===0 ? unitsText = ''+u : unitsText += ' + ' + u);

            setPropertiesAndUnitsText([propertiesText, unitsText]);
        }
    },[propertiesAndUnits])


    const getFieldName = (request) => {
        if(request?.field?.name?.indexOf("'") > -1) {
            return request?.field?.name?.split("'")[1];
        } else {
            if(request?.field?.name) {
                return request?.field?.name;
            } else {
                return request?.field;
            }
        }
    }

    const getCodeFromSubRequest = (subRequest) => {
        if(subRequest?.initialWorkList && 'initialWorkList' in subRequest) {
            return subRequest?.initialWorkList?.maintenanceCode;
        } else {
            return subRequest?.serviceDetails?.subServiceCode;
        }
    }

    const sumPartsCosts = (request) => {
        let total = 0;
        if(request?.subRequests && Array.isArray(request?.subRequests) && request?.subRequests?.length > 0) {
            request?.subRequests?.forEach(sub=>{
                total = total + sub?.invoiceDetails?.partsCost;
            });
        }
        return total;
    }

    const sumPartsCostsDelivery = (request) => {
        let total = 0;
        if(request?.subRequests && Array.isArray(request?.subRequests) && request?.subRequests?.length > 0) {
            request?.subRequests?.forEach(sub=>{
                total = total + sub?.invoiceDetails?.deliveryCostOfParts;
            });
        }
        return total;
    }


  return (
    singleRequest && <div>
      <PageHeader pageTitle={pageTitle} isColored />
      <PageMain>
        <div className="fixed top-0 left-0 w-full h-full bg-grey-50 !z-[-1]"></div>
        <MainContent>
            <div className="bg-white rounded-[8px] overflow-hidden mb-[25px]">

                <div className={`${GetRequestStyle(singleRequest?.status,'bg')} text-white h-[56px] p-[16px] mb-[10px]`}>
                    <h2 className="text-[16px] font-[600]">{GetRequestStyle(singleRequest?.status,'heading')}</h2>
                </div>

                <p className="text-[16px] text-grey-900 font-[700] px-[16px]">تفاصيل الطلب</p>
                <div className="">
                    <div className="text-[14px] text-grey-600 font-[500] h-[30px] leading-[30px] my-[10px] px-[16px]">
                        <span className="inline-block align-middle w-[20px] h-[20px] me-[4px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/note.svg')", backgroundSize: "20px"}}></span>
                        <span className="text-[12px] text-grey-900 font-[600]">رقم الطلب:</span> {singleRequest?.prettyId}
                    </div>
                    <div className="text-[14px] text-grey-600 font-[500] h-[30px] leading-[30px] my-[10px] mx-[16px] border-t-[1px] border-t-grey-200">
                        <span className="inline-block align-middle w-[20px] h-[20px] me-[4px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/calendar.svg')", backgroundSize: "20px"}}></span>
                        <span className="text-[12px] text-grey-900 font-[600]">تاريخ الانشاء:</span> {new Date(singleRequest?.createdAt).toLocaleDateString("ar-eg", {year: 'numeric', month: 'long', day: 'numeric'})}
                    </div>
                    <div className="text-[14px] text-grey-600 font-[500] h-[30px] leading-[30px] my-[10px] mx-[16px] border-t-[1px] border-t-grey-200">
                        <span className="inline-block align-middle w-[20px] h-[20px] me-[4px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/user-square.svg')", backgroundSize: "20px"}}></span>
                        <span className="text-[12px] text-grey-900 font-[600]">انشئ بواسطة:</span> {singleRequest?.statuses[0]?.createdBy?.name}
                    </div>
                    <div className="text-[14px] text-grey-600 font-[500] h-[30px] leading-[30px] my-[10px] mx-[16px] border-t-[1px] border-t-grey-200">
                        <span className="inline-block align-middle w-[20px] h-[20px] me-[4px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/main-property-icon.svg')", backgroundSize: "20px"}}></span>
                        <span className="text-[12px] text-grey-900 font-[600]">المنشأة:</span> {propertiesAndUnitsText && propertiesAndUnitsText[0]}
                    </div>
                    <div className="text-[14px] text-grey-600 font-[500] h-[30px] leading-[30px] my-[10px] mx-[16px] border-t-[1px] border-t-grey-200">
                        <span className="inline-block align-middle w-[20px] h-[20px] me-[4px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/house.svg')", backgroundSize: "20px"}}></span>
                        <span className="text-[12px] text-grey-900 font-[600]">الوحدة:</span> {propertiesAndUnitsText && propertiesAndUnitsText[1]}
                    </div>
                </div>

            </div>

            <div className="bg-white rounded-[8px] overflow-hidden mb-[15px]">
                <p className="text-[24px] leading-[36px] text-grey-900 font-[700] mx-[16px] py-[16px] border-b-[1px] border-b-grey-200">الخدمات المطلوبة</p>
                {singleRequest?.subRequests && 'subRequests' in singleRequest && singleRequest?.subRequests[0]?.initialWorkList ?
                singleRequest?.subRequests?.map((subRequest, index)=> {
                        return (<div key={`subrequest-${index}`} className="p-[16px] bg-grey-50 m-[16px] border-[1px] border-grey-200 rounded-[8px]">
                            <div className="flex flex-nowrap mb-[10px] border-b-[1px] border-b-grey-200">
                                <div className="w-[50%] text-[12px] text-grey-900 font-[600] pb-[16px]">رقم الطلب الفرعي: <span dir="ltr" className="font-[500] text-[14px] text-grey-600">{singleRequest?.prettyId + '-' + (index+1)}</span></div>
    
                                <div className="w-[50%] text-end">
                                حالة الطلب الفرعي: <span className={`${GetRequestStyle(subRequest?.status,'bg')} text-white font-bold px-[10px] rounded-[3px]`}>{GetRequestStyle(subRequest?.status,'label')}</span>
                                </div>
                            </div>
                            {subRequest?.initialWorkList?.map((service,i)=>{
                                return (
                                    <div className="flex flex-nowrap gap-x-[10px] mb-[15px]">
                                        <div className="w-[25%]">
                                            <div className="inline-block align-middle w-[32px] h-[32px] bg-[#9E9E9E] me-[10px]" style={{maskImage:"url('/assets/images/"+FIELDS[getFieldName(singleRequest)]?.icon+"')", maskSize: "contain", maskRepeat: "no-repeat"}}></div>
                                            <div className="inline-block align-middle">
                                                <p className="text-[#616161]">الخدمة الأساسية</p>
                                                <p className="text-[#212121] text-[16px] font-[600]">{FIELDS[getFieldName(singleRequest)]?.ar}</p>
                                            </div>
                                        </div>
                                        <div className="w-[25%]">
                                            <p className="text-[#616161]">نوع الخدمة</p>
                                            <p className="text-[#212121] text-[16px] font-[600]">{GetServiceByCode(getFieldName(singleRequest), service?.maintenanceCode)[0]}</p>
                                        </div>
                                        <div className="w-[25%]">
                                            <p className="text-[#616161]">أسم الخدمة الفرعية</p>
                                            <p className="text-[#212121] text-[16px] font-[600]">{GetServiceByCode(getFieldName(singleRequest), service?.maintenanceCode)[1]}</p>
                                        </div>
                                        <div className="w-[25%]">
                                            <p className="text-[#616161]">الكمية</p>
                                            <p className="text-[#212121] text-[16px] font-[600]">{service?.amount}</p>
                                        </div>
                                    </div>
                                )})}
                            <div className="flex flex-nowrap mb-[10px]">
                                <div className="w-full text-[16px] text-grey-900 font-[600]">اسم الوحدة</div>
                            </div>
                            <div>
                                <div className="inline-block align-middle px-[8px] rounded-[8px] h-[32px] leading-[32px] text-grey-900 font-[500] text-[16px] bg-[rgba(30,41,59,0.1)] me-[10px]">
                                    {subRequest?.unitName}
                                </div>
                            </div>
                            <div className="border-t-[1px] border-t-grey-200 mt-[16px]">
                                <div className="flex flex-nowrap mt-[10px] leading-[30px] h-[30px]">
                                    <div className="w-[50%] text-[16px] text-grey-600 font-[500]">اجمالي قطع الغيار المطلوبة</div>
                                    <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{subRequest?.invoiceDetails?.partsCost?.toFixed(2)} sr</div>
                                </div>
                                {subRequest?.invoiceDetails?.partsCost && subRequest?.invoiceDetails?.partsCost > 0 ? <div className="flex flex-nowrap mt-[10px] leading-[30px] h-[30px]">
                                    <div className="w-[50%] text-[16px] text-grey-600 font-[500]">رسوم توصيل قطع الغيار</div>
                                    <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{subRequest?.invoiceDetails?.deliveryCostOfParts?.toFixed(2)} sr</div>
                                </div> : ''}
                                <div className="flex flex-nowrap mt-[10px] leading-[30px] h-[30px]">
                                    <div className="w-[50%] text-[16px] text-grey-600 font-[500]">راتب  العمالة بدون ضريبة</div>
                                    <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{subRequest?.invoiceDetails?.workerWage?.toFixed(2)} sr</div>
                                </div>
                                <div className="flex flex-nowrap mt-[10px] leading-[30px] h-[30px]">
                                    <div className="w-[50%] text-[16px] text-grey-600 font-[500]">الضريبة</div>
                                    <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{subRequest?.invoiceDetails?.vatValue?.toFixed(2)} sr</div>
                                </div>
                                <div className="flex flex-nowrap mt-[10px] leading-[30px] h-[30px]">
                                    <div className="w-[50%] text-[16px] text-grey-600 font-[500]">اجمالي تكلفة الطلب الفرعي</div>
                                    <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{subRequest?.invoiceDetails?.total?.toFixed(2)} sr</div>
                                </div>
                            </div>
                        </div>)
                })
                : singleRequest?.units?.map((subRequest, index)=> {
                    return (<div key={`subrequest-${index}`} className="p-[16px] bg-grey-50 m-[16px] border-[1px] border-grey-200 rounded-[8px]">
                        <div className="flex flex-nowrap mb-[10px] border-b-[1px] border-b-grey-200">
                            <div className="w-[50%] text-[12px] text-grey-900 font-[600] pb-[16px]">رقم الطلب الفرعي: <span dir="ltr" className="font-[500] text-[14px] text-grey-600">{singleRequest?.prettyId + '-' + (index+1)}</span></div>

                            <div className="w-[50%] text-end">
                                حالة الطلب الفرعي <span>{subRequest?.status}</span>
                            </div>
                        </div>
                        <div className="flex flex-nowrap gap-x-[10px] mb-[15px]">
                            <div className="w-[25%]">
                                <div className="inline-block align-middle w-[32px] h-[32px] bg-[#9E9E9E] me-[10px]" style={{maskImage:"url('/assets/images/"+FIELDS[getFieldName(singleRequest)]?.icon+"')", maskSize: "contain", maskRepeat: "no-repeat"}}></div>
                                <div className="inline-block align-middle">
                                    <p className="text-[#616161]">الخدمة الأساسية</p>
                                    <p className="text-[#212121] text-[16px] font-[600]">{FIELDS[getFieldName(singleRequest)]?.ar}</p>
                                </div>
                            </div>
                            <div className="w-[25%]">
                                <p className="text-[#616161]">نوع الخدمة</p>
                                <p className="text-[#212121] text-[16px] font-[600]">{GetServiceByCode(getFieldName(singleRequest), subRequest?.serviceDetails?.subServiceCode)[0]}</p>
                            </div>
                            <div className="w-[25%]">
                                <p className="text-[#616161]">أسم الخدمة الفرعية</p>
                                <p className="text-[#212121] text-[16px] font-[600]">{GetServiceByCode(getFieldName(singleRequest), subRequest?.serviceDetails?.subServiceCode)[1]}</p>
                            </div>
                            <div className="w-[25%]">
                                <p className="text-[#616161]">عدد الوحدات</p>
                                <p className="text-[#212121] text-[16px] font-[600]">{singleRequest?.units?.length} وحدة</p>
                            </div>
                        </div>
                        <div className="flex flex-nowrap mb-[10px]">
                            <div className="w-full text-[16px] text-grey-900 font-[600]">الوحدات</div>
                        </div>
                        <div>
                            <div className="inline-block align-middle px-[8px] rounded-[8px] h-[32px] leading-[32px] text-grey-900 font-[500] text-[16px] bg-[rgba(30,41,59,0.1)] me-[10px]">
                                {subRequest?.unitName}
                            </div>
                        </div>
                        <div className="border-t-[1px] border-t-grey-200 mt-[16px]">
                            <div className="flex flex-nowrap mt-[10px] leading-[30px] h-[30px]">
                                <div className="w-[50%] text-[16px] text-grey-600 font-[500]">اجمالي قطع الغيار المطلوبة</div>
                                <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{subRequest?.unitInvoiceDetails?.partsCost?.toFixed(2)} sr</div>
                            </div>
                            <div className="flex flex-nowrap mt-[10px] leading-[30px] h-[30px]">
                                <div className="w-[50%] text-[16px] text-grey-600 font-[500]">اجمالي راتب العمالة</div>
                                <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{subRequest?.unitInvoiceDetails?.workerWage?.toFixed(2)} sr</div>
                            </div>
                        </div>
                    </div>)
                })}
            </div>

            <div className="bg-white rounded-[8px] overflow-hidden">
                <p className="text-[24px] leading-[36px] text-grey-900 font-[700] mx-[16px] py-[16px] border-b-[1px] border-b-grey-200 border-dotted">تفاصيل الفاتورة</p>
                  
                <div className="px-[16px] pb-[16px]">
                    <div className="border-t-[1px] border-dotted border-grey-300">
                        <div className="flex flex-nowrap my-[10px] leading-[30px] h-[30px]">
                            <div className="w-[50%] text-[16px] text-grey-600 font-[500]">اجمالي قطع الغيار المطلوبة</div>
                            <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{sumPartsCosts(singleRequest)?.toFixed(2)} sr</div>
                        </div>
                        {sumPartsCosts(singleRequest) > 0 && <div className="flex flex-nowrap my-[10px] leading-[30px] h-[30px]">
                            <div className="w-[50%] text-[16px] text-grey-600 font-[500]">اجمالي رسوم توصيل قطع الغيار</div>
                            <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{sumPartsCostsDelivery(singleRequest)?.toFixed(2)} sr</div>
                        </div>}
                        <div className="flex flex-nowrap my-[10px] leading-[30px] h-[30px]">
                            <div className="w-[50%] text-[16px] text-grey-600 font-[500]">اجمالي راتب العمالة بدون ضريبة</div>
                            <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{singleRequest?.invoiceDetails?.workersWage?.toFixed(2)} sr</div>
                        </div>
                    </div>
                    <div className="border-t-[1px] border-dotted border-grey-300">
                        <div className="flex flex-nowrap my-[10px] leading-[30px] h-[30px]">
                            <div className="w-[50%] text-[16px] text-grey-600 font-[500]">الضريبة</div>
                            <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{singleRequest?.invoiceDetails?.vatValue?.toFixed(2)} sr</div>
                        </div>
                    </div>
                    <div className="border-t-[1px] border-dotted border-grey-300">
                        <div className="flex flex-nowrap mt-[10px] leading-[30px] h-[30px]">
                            <div className="w-[50%] text-[16px] text-grey-600 font-[500]">اجمالي تكلفة الطلب</div>
                            <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{singleRequest?.invoiceDetails?.total?.toFixed(2)} sr</div>
                        </div>
                    </div>
                </div>
                

            </div>

            <div className="h-[25px]"></div>
          
        </MainContent>
      </PageMain>
    </div>
  );
};

export default SingleRequestPage;
