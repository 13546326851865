import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from 'react-select';

export default function MultiSelect({label,options=[],value,classNamePrefix,placeholder,onChange,disabled,error,errorMessage,translateError=false,isRequird=false,role,infoIcon=false, handleClickInfoIcon,noOptionsText,isMulti,isOptionDisabled}){

    const [showPassword, setShowPassword] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const {t,i18n} = useTranslation();


    const getSelectedValue = () => {
        if(Array.isArray(options)) {
            if(isMulti && Array.isArray(value)) {
                let selectedValues = [];
                value?.map((val) => {
                    selectedValues.push(options[options.findIndex(option=> option.value == val)])
                });
                setSelectedValue(selectedValues);
            } else {
                if(value) {
                    setSelectedValue(options[options.findIndex(o=>o.value === value)]);
                } else {
                    setSelectedValue('');
                }
            }
        }
    }

    useEffect(()=>{
        getSelectedValue();
    },[value])


    return (
        <div className="min-h-[40px] w-[100%] mb-[10px] relative">
            { label && <label htmlFor={label} className="text-grey-900 text-[14px] font-[600] leading-[21px] mb-[3px] block">{label}{isRequird && <span className="text-danger">*</span>}</label> } 
            { infoIcon && <span className="cursor-pointer w-[24px] h-[24px] block !bg-center !bg-no-repeat absolute top-[-5px] end-0" style={{background:"url('/assets/images/info-icon.svg')"}} onClick={()=>handleClickInfoIcon()}></span>}
            <Select isOptionDisabled={isOptionDisabled} isMulti={isMulti} value={selectedValue} classNamePrefix={`${classNamePrefix} multiSelect`} className={`${error ? 'selectError' : '' }`} options={options} placeholder={placeholder} noOptionsMessage={() => "لاتوجد اختيارات"} onChange={onChange} />
            {errorMessage && <p className="text-danger text-[14px] font-[400] leading-[20px] pe-[5px] pt-[3px] mb-[0] block"><img src="/assets/images/error.svg" alt="" className="inline w-[16px] h-[16px] align-middle me-[3px]" />{translateError ? t(errorMessage) : errorMessage}</p>}
        </div>
    )
}