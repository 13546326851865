export default function Button({type='button',onClick,disabled,text,style='primary',width,center=false,color=false,widthClass='',bgColor='',textColor='', textAlign='',display='',dropShadow='',customClass='', wrapperClass=''}){

    return (
        <div className={`h-[40px] w-[100%] mb-[10px] mt-[24px] relative ${wrapperClass}`}>
            <button
                type={type}
                onClick={onClick}
                disabled={disabled}
                className={`${ style == 'primary' ? 'transition-all ease-in-out duration-300 hover:scale-105 font-[600] outline-none h-[48px] w-[100%] rounded-[8px] justify-center items-center inline-flex leading-[48px] bg-blue border-blue text-white text-[16px]': 'text-white bg-primary hover:bg-primary/90 shadow-[0px_8px_16px_0px_rgba(75,187,133,0.1))] outline-none rounded-[14px] text-[15px] px-[23px] py-[9px] text-center inline-flex items-center transition-all duration-100 ease-in-out'} ${ disabled ? '!bg-grey-300 !border-grey-300 cursor-not-allowed !scale-100' : '' } ${center && 'text-center inline-block'} ${widthClass} ${bgColor} ${textColor} ${textAlign} ${display} ${dropShadow} ${customClass}`}
            >
                {text}
            </button>
        </div>
    )
}