import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import PageMain from "../../containers/shared/PageMain";
import PageHeader from "../../components/shared/PageHeader";
import MainContent from "../../containers/shared/MainContent";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts";
import { BarChart } from '@mui/x-charts/BarChart';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import apiClient from "../../components/utils/apiClient";
import useUser from "../../components/helpers/useUser";
import RequestsTable from "../../components/requests/RequestsTable";
import Loading from "../../components/shared/Loading";
import { FIELDS } from "../../components/utils/constants";


const HomePage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('الرئيسية');
    const navigate = useNavigate();
    const pageTitle = {title: 'الرئيسية'};

    const [requests, setRequests] = useState(null);
    const [user,setUser] = useUser();
    const [dashboard,setDashboard] = useState(null);


    const getRequests = async () => {
      try {
        const response = await apiClient.get(`/b-business-requests/?business=${user?.business}&limit=5`);
        if(response?.data) {
          setRequests(response?.data?.requests);
        } else {

        }
      } catch(err) {}
    }

    const getDashboard = async () => {
      
      try {
        const response = await apiClient.get(`/b-business/${user?.business}/dashboard`);
        if(response?.data) {
          setDashboard(response?.data[0]);
        } else {

        }
      } catch(err) {}
    }


    useEffect(()=>{
      if(user?.business) {
        getRequests();
        getDashboard();
      }
    },[user])


    const sum = (items, prop) => {
        return items.reduce( function(a, b){
          return a + b[prop];
        }, 0);
    };



    const TotalSpend = (dashboard && dashboard?.businessDetails[0]?.fieldSpentCredits) ? sum(dashboard?.businessDetails[0]?.fieldSpentCredits,'spentCredits') : 0;
    const credits = (dashboard && dashboard?.businessDetails[0]?.credits) ? dashboard?.businessDetails[0]?.credits : 0;
    const TotalOfAllTime = (TotalSpend && credits) ? TotalSpend + credits : 0;

    const pieValue = (TotalSpend && TotalOfAllTime) ? (TotalSpend/TotalOfAllTime)*100 : 0;



    const selectedFields = ["air-conditioning","plumbing","carpentry","home-appliances","satellite-tv"];

    const getFieldColor = (field) => {
      let color;
      switch( field ) {
        case "air-conditioning":
          color = "#F4A79D";
          break;
        case "plumbing":
          color = "#F68D2B";
          break;
        case "carpentry":
          color = "#344BFD";
          break;
        case "home-appliances":
          color = "#49A677";
        case "satellite-tv":
          color = "#FA898B";
        default:
          color = "#E2E8F0";
      }
      return color;
    }


    const filterData1 = (dashboard && dashboard?.businessDetails[0]?.fieldSpentCredits) ? dashboard?.businessDetails[0]?.fieldSpentCredits?.filter(item=> selectedFields.includes(item?.field)) : [];
    const data1 = (filterData1 && filterData1?.length) ? filterData1.map((item=> { return { value: item?.spentCredits, color: getFieldColor(item?.field), field: item?.field } } )) : [];
    const filterOtherData = (dashboard && dashboard?.businessDetails[0]?.fieldSpentCredits) ? dashboard?.businessDetails[0]?.fieldSpentCredits?.filter(item=> !selectedFields.includes(item?.field)) : [];
    const otherData = (filterOtherData && filterOtherData?.length) ? filterOtherData.map((item=> {  return { value: item?.spentCredits, color: getFieldColor(item?.field), field: item?.field}  } )) : [];
    const totalOtherData = (otherData && otherData?.length > 0) ? sum(otherData,'value') : 0;

    if(totalOtherData > 0) {
      data1.push({ value: totalOtherData, color: getFieldColor('other'), field: 'other'});
    }

  


    const TOTAL = (data1 && data1?.length > 0) ? data1.map((item) => item.value).reduce((a, b) => a + b, 0) : 0;
    const getArcLabel = (params) => {
      const percent = params.value / TOTAL;
      return `${(percent * 100).toFixed(0)}%`;
    };



    

  return (
    <div>
      <PageHeader isColored pageTitle={pageTitle} />
      <PageMain>
        <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
        <MainContent>
          {dashboard && <><div className="flex flex-nowrap gap-x-[25px]">
            <div className="w-[40%] h-[358px] flex flex-wrap content-start rounded-[10px] bg-white p-[15px]">
              <p className="w-full text-homeDark font-[600] text-[20px] leading-[30px] h-[30px]">اجمالي ما تم صرفة من محفظتك</p>
              <Gauge
                className=""
                value={pieValue}
                startAngle={-90}
                endAngle={90}
                width={280}
                height={200}
                text={()=>''}
                sx={{
                  [`& .${gaugeClasses.valueText}`]: {
                    fontSize: 32,
                    transform: 'translate(7px, 4px)',
                  },
                  [`& .${gaugeClasses.valueArc}`]: {
                    fill: '#FBA834',
                  },
                  [`& .${gaugeClasses.referenceArc}`]: {
                    fill: '#E2E8F0',
                  },
                }}
              />
              <p className="w-full text-darkBlue text-[30px] text-center mt-[-90px]">SAR <strong>{credits}</strong></p>
              <p className="w-full text-grey-900 text-[22px] text-center mt-[-40px]">رصيد متاح</p>
              <div className="pt-[25px] w-full text-center">
                <div className="inline-block me-[100px] text-start whitespace-nowrap text-grey-900 text-[16px] font-[600] leading-[24px]">
                  تم صرف
                  <div className="text-darkBlue font-[500]"><span className="inline-block w-[12px] h-[12px] rounded-full bg-[#FBA834] me-[3px]"></span> SAR {TotalSpend}</div>  
                </div>
                <div className="inline-block text-start whitespace-nowrap text-grey-900 text-[16px] font-[600] leading-[24px]">
                  باقي
                  <div className="text-darkBlue font-[500]"><span className="inline-block w-[12px] h-[12px] rounded-full bg-[#E2E8F0] me-[3px]"></span> SAR {credits}</div>  
                </div>
              </div>
            </div>
            <div className="w-[60%] h-[358px] rounded-[10px] bg-white p-[15px]">
              <div className="flex flex-nowrap h-[358px] gap-x-[15px]">
                <div className="w-[50%]">
                  <p className="w-full text-homeDark font-[600] text-[20px] leading-[30px] h-[30px]">ما تم صرفة حسب نوع الخدمة</p>
                  {data1 && data1?.length > 0 
                  ? data1?.map((item,i)=>{
                    return (
                      <div key={'spendOfFields'+i} className="flex flex-nowrap gap-x-[5px] max-w-[300px] mt-[30px] mb-[20px]">
                        <div className="w-[50%] leading-[24px] font-[600] text-[16px] text-homeDark"><span className={`inline-block w-[12px] h-[12px] rounded-full bg-[${item?.color}] me-[3px]`}></span> {item?.field === 'other' ? 'اخري' : FIELDS[item?.field]?.ar}</div>
                        <div className="w-[50%] leading-[24px] font-[500] text-[16px] text-blue text-end">SAR {item?.value}</div>
                      </div>
                    )
                  })
                  : <p className="leading-[50px] text-blue">لاتوجد بيانات حتي الان</p>}
                </div>
                <div className="w-[50%] h-[358px] flex content-start pt-[25px]">
                  <PieChart
                  width={280}
                  height={280}
                  colors={['#F4A79D','#F68D2B','#344BFD','#49A677','#FA898B','#E2E8F0']}
                  series={[
                    {
                      data: data1?.length > 0 ? data1 : [],
                      innerRadius: 80,
                      outerRadius: 140,
                      paddingAngle: 0,
                      cornerRadius: 0,
                      startAngle: 0,
                      endAngle: 360,
                      arcLabel: getArcLabel,
                      cx: 135,
                      cy: 135,
                    }
                  ]}
                  sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                      fill: '#0F172A',
                      fontSize: 10,
                      fontWeight: 700,
                    },
                  }}
                />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-nowrap gap-x-[25px] mt-[25px]">
            <div className="w-[50%] h-[358px] flex flex-wrap content-start rounded-[10px] bg-white p-[15px]">
              <p className="w-full text-homeDark font-[600] text-[20px] leading-[30px] h-[30px]">اكثر خمس وحدات طلبا</p>
              <BarChart
                borderRadius={6}
                colors={['#042254']}
                height={300}
                grid={{ horizontal: true }}
                series={[{ data: dashboard?.topUnits?.map((unit=> unit?.requestCount)) }]}
                margin={{
                  top: 30,
                  bottom: 30,
                }}
                yAxis={[
                  {
                    colorMap:
                      undefined,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: dashboard?.topUnits?.map((unit=> unit?.unitName)),
                    categoryGapRatio: 0.75
                  },
                ]}
              />
            </div>
            <div className="w-[50%] h-[358px] flex flex-wrap content-start rounded-[10px] bg-white p-[15px]">
              <p className="w-full text-homeDark font-[600] text-[20px] leading-[30px] h-[30px]">اكثر خمس منشآت طلبا</p> 
              <BarChart
                borderRadius={6}
                colors={['#042254']}
                height={300}
                grid={{ horizontal: true }}
                series={[{ data: dashboard?.topProperties?.map((property=> property?.requestCount)) }]}
                margin={{
                  top: 30,
                  bottom: 30,
                }}
                yAxis={[
                  {
                    colorMap:
                      undefined,
                  },
                ]}
                xAxis={[
                  {
                    scaleType: 'band',
                    data: dashboard?.topProperties?.map((property=> property?.propertyName)),
                    categoryGapRatio: 0.75
                  },
                ]}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-nowrap mb-[-10px] mt-[25px]">
              <div className="w-[50%] text-[20px] text-homeDark font-[600] leading-[30px]">اخر طلباتك في فني</div>
              <div className="w-[50%] text-end"><Link className="font-[600] text-[16px] text-blue leading-[24px]" to={'/ar/requests'}>عرض الكل</Link></div>
            </div>
          {requests ? Array.isArray(requests) && requests?.length > 0 
          ? <RequestsTable requests={requests} type="main" />
          : <div className="pt-[100px] text-center">
              <div className="inline-block w-[188px] h-[188px] !bg-contain !bg-center" style={{background: "url('/assets/images/note.svg')"}}></div>
              <div className="w-full text-center text-grey-900 text-[24px] font-[500] mb-[25px]">لم تقم باي طلبات الي الان</div>
              <Link className="inline-block min-w-[320px] h-[48px] leading-[48px] rounded-[8px] text-white bg-grey-800 text-[18px] font-[600]" to={'/ar/requests/add'}>إنشاء طلب جديد</Link>
          </div> : <></> }
          </div></>}
      </MainContent>
      </PageMain>

      {!dashboard && <Loading showLoading={true} />}
    </div>
  );
};

export default HomePage;
