import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageMain from "../../containers/shared/PageMain";
import MainContent from "../../containers/shared/MainContent";
import PageHeader from "../../components/shared/PageHeader";
import useUser from "../../components/helpers/useUser";
import SingleImageUpload from "../../components/shared/SingleImageUpload";
import SingleImageCompress from "../../components/helpers/SingleImageCompress";
import Loading from "../../components/shared/Loading";
import apiClient from "../../components/utils/apiClient";
import {initializeApp, getApp, getApps} from "firebase/app";
import { getStorage } from "firebase/storage";
import { getDownloadURL, uploadBytesResumable, ref } from "firebase/storage";
import { firebaseConfig } from "../../components/utils/constants";
import moment from "moment";
import Toast from "../../components/shared/Toast";
import ModalContainer from "../../containers/shared/ModalContainer";
import NormalButton from "../../components/shared/NormalButton";

const SettingsHomePage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('الاعدادات');
    const navigate = useNavigate();
    const [user,setUser] = useUser();
    const pageTitle = {title: 'مرحبا بك',subTitle:`${user?.name} في فني`};
    const [activeMenu,setActiveMenu] = useState('profile');
    const [notificationStatus,setNotificationStatus] = useState(true);
    const [account,setAccount] = useState(null);
    const [userObject,setUserObject] = useState(null);
    const [role,setRole] = useState(null);
 
    const [uploadPreview, setUploadPreview] = useState(null);
    const [compressedImage, setCompressedImage] = useState(null);
    const [toast, setToast] = useState({show: false,type: null, message:'' });
    const [deleteUserModal, setDeleteUserModal] = useState(false);
    const [isAddUserFormDisabled, setIsAddUserFormDisabled] = useState(false);

    const menu = [
      {name: 'profile', icon: '/assets/images/profile-icon.svg', title: 'الملف الشخصي' },
      {name: 'notification', icon: '/assets/images/bell-icon.svg', title: 'جرس الإشعارات' },
      {name: 'payments', icon: '/assets/images/payments-icon.svg', title: 'مدفوعاتي' },
    ];

    const handleClickMenuItem = (item) => {
      if(item === 'payments') {
        navigate('/ar/wallet');
      } else {
        setActiveMenu(item);
      }
    }

    const handleClickLogout = () => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('b-user');
        navigate(`/auth/login`);
    }

    const handleClickDeleteUser = () => {
      setDeleteUserModal(true);
    }

    const handleCloseModalDelete = () => {
        setDeleteUserModal(false);
    }

    const handleClickDeleteUserAction = async () => {
      const path = `/b-users/${user?._id}`;
      try {
        const response = await apiClient.delete(path);
        if(response?.data || response?.status === 201) {
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('b-user');
          window.location.reload();
        } else {

        }
      } catch(err) {}
    }

    function handleChangeUpload(e) {
      setAccount(null);
      SingleImageCompress(e.target.files[0]).then(compressedFile=>setCompressedImage(compressedFile));
      setUploadPreview(URL.createObjectURL(e.target.files[0]));
    }

    const getAccount = async () => {
      const path = role === "OWNER" ? `/b-owners/${user?._id}` : `/b-users/${user?._id}`;
      try {
        const response = await apiClient.get(path);
        if(response?.data) {
          setAccount(response?.data);
          let finalObject = {};
          Object.keys(response?.data)?.forEach(key=>{
            if(key !== '_id') {
              finalObject[key] = response?.data[key];
            }
          });
          setUserObject(finalObject);
        } else {

        }
      } catch(err) {}
    }

    const handleUpdateAccount = async (image) => {
      const body = userObject;
      body.avatar = image;
      const path = role === "OWNER" ? `/b-owners/${user?._id}` : `/b-users/${user?._id}`;
      try {
        const response = await apiClient.put(path,body);
        if(response?.data || response?.status === 201) {
          window.location.reload();
        } else {

        }
      } catch(err) {if(err?.response?.data) {
        setToast({show:true,type:'error',message:'حدث خطأ في رفع الصورة ,من فضلك حاول مرة اخري في وقت لاحق'});
        setAccount(true);
      }}
    }

    useEffect(()=>{
      if(compressedImage) {
        const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
        const storage = getStorage(app);
        const file = compressedImage;
        const time = Date.parse(moment().locale('en').format('YYYY-MM-DD HH:mm:ss')) / 1000;
        const storageRef = ref(storage,'fanni-business/userImages/'+time+'_'+file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            (error) => {
                setToast({show:true,type:'error',message:'حدث خطأ في رفع الصورة ,من فضلك حاول مرة اخري في وقت لاحق'});
            },
            () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadedURL) => {
                handleUpdateAccount(downloadedURL);
            });
            }
        );
    }
    },[compressedImage])

    useEffect(()=>{
      if(user) {
        setRole(user?.role);
      }
    },[user]);

    useEffect(()=>{
      if(role) {
        getAccount();
      }
    },[role]);

    
    
    
  return (
    <div>
      <PageHeader pageTitle={pageTitle} subTitleSize={'big'} blueTitle={true} />
      <PageMain>
        <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
        <MainContent>
          {(account || compressedImage) && <div className="flex flex-nowrap gap-x-[15px] mt-[15px]">
            <div className="w-[325px]">
              <div className="bg-white border border-grey6 rounded-[12px] min-h-[700px]">
                <div className="">
                  <span className="w-[90px] h-[90px] rounded-full block m-[15px_auto] !bg-offWhite !bg-[50%_10px] !bg-no-repeat" style={{background:`url('${user?.avatar ? user?.avatar : '/assets/images/profile.svg' }')`, backgroundSize:90}}></span>
                  <p className="text-settingsDark text-[24px] leading-[36px] mt-[10px] font-[500] text-center">مرحباً بك</p>
                  <p className="text-settingsDarker text-[20px] leading-[30px] font-[700] text-center">{user?.name}</p>
                </div>
                <div className="mt-[15px] py-[25px] relative min-h-[440px]">
                  <span className="absolute top-0 left-[15px] w-[calc(100%-30px)] h-[1px] bg-settingsBorder block"></span>
                    <ul>
                      {menu && menu?.length > 0 && menu?.map((item,index)=>{
                        return (<li key={`menu-item-`+index} className={`${activeMenu === item?.name ? 'text-darkBlue' : 'text-settingsDark'} font-[700] text-[16px] px-[15px] cursor-pointer mb-[10px] leading-[48px] h-[48px] relative`} onClick={()=>handleClickMenuItem(item?.name)}>{activeMenu === item?.name && <div className="absolute h-[48px] w-[calc(100%-10px)] start-0 border-s-[3px] border-darkBlue bg-blue/10 rounded-e-full"></div>}<span className={`inline-block align-middle w-[24px] h-[24px] me-[10px]  ${activeMenu === item?.name ? 'bg-darkBlue' : 'bg-settingsDark' }`} style={{maskImage:`url('${item?.icon}')`, maskPosition:"center", maskRepeat: "no-repeat"}}></span> { item?.title }</li>)
                      })}
                    </ul>
                  <span className="absolute bottom-0 left-[15px] w-[calc(100%-30px)] h-[1px] bg-settingsBorder block"></span>
                </div>
                <div>
                  <div className={`text-settingsDark font-[700] text-[16px] px-[15px] cursor-pointer mb-[10px] leading-[48px] h-[48px] relative`} onClick={()=>handleClickLogout()}><span className={`inline-block align-middle w-[24px] h-[24px] me-[10px] bg-settingsDark`} style={{maskImage:`url('/assets/images/logout-icon.svg')`, maskPosition:"center", maskRepeat: "no-repeat"}}></span> تسجيل الخروج</div>
                </div>
              </div>
            </div>
            <div className="w-[calc(100%-325px)]">
              <div className="bg-white border border-grey6 rounded-[12px] min-h-[620px]">
              {activeMenu === 'profile' && 
                <div className="p-[25px]">
                  <h2 className="text-settingsDarker font-[700] text-[24px] leading-[36px] mb-[10px]">بيانات الحساب</h2>
                  <p className="text-settingsDarker font-[500] text-[16px] leading-[24px]">معلومات الحساب الخاص بك يمكنك تحديثها بناء علي رغبتك</p>
                  <div className="min-h-[410px]">
                    <div className="flex flex-nowrap border-b border-b-grey6 h-[56px] mt-[42px]">
                      <div className="w-[200px] text-[16px] text-settingsDarker font-[500]">الصورة</div>
                      <div className="w-[calc(100%-320px)] text-[16px] text-settingsDarker font-[500]">الصورة تساعدك علي تخصيص وتمييز صورة حسابك</div>
                      <div className="w-[120px] text-end"><SingleImageUpload onchange={handleChangeUpload} preview={uploadPreview} inline profile toTop /></div>
                    </div>
                    <div className="flex flex-nowrap border-b border-b-grey6 h-[43px] mt-[12px]">
                      <div className="w-[200px] text-[16px] text-settingsDarker font-[500]">رقم الهاتف</div>
                      <div className="w-[calc(100%-320px)] text-[16px] text-settingsDarker font-[700]"><span dir="ltr">{`+${user?.username}`}</span></div>
                      <div className="w-[120px] text-end"><img className="inline-block" src="/assets/images/arrow-left.svg" alt="" /></div>
                    </div>
                  </div>
                  <div className="border border-grey6 rounded-[8px] p-[15px]">
                    <div className="flex flex-nowrap">
                      <div className="w-[75%]">
                        <h2 className="text-settingsDarker font-[700] text-[20px] leading-[30px]">حذف الحساب</h2>
                        <p className="text-tgrey font-[500] text-[16px] leading-[24px]">سيؤدي حذف الحساب الي حذف معلوماتك نهائياً</p>
                      </div>
                      <div className="w-[25%] text-end">
                        <div className="inline-block cursor-pointer w-[122px] h-[40px] border border-grey6 rounded-[8px] text-center leading-[36px] text-settingsRed text-[20px] font-[700] mt-[7px]" onClick={()=>handleClickDeleteUser()}><img src="/assets/images/red-delete.svg" className="inline-block align-middle me-[3px]" alt="" /> حذف</div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {activeMenu === 'notification' && 
              <div className="p-[25px]">
                <h2 className="text-settingsDarker font-[700] text-[24px] leading-[36px] mb-[10px]">اعدادات الاشعارات</h2>
                <p className="text-settingsDarker font-[500] text-[16px] leading-[24px]">اشعارات طلب جديد</p>
                <div className="border border-[rgba(0,0,0,5%)] shadow-[0_2px_1px_0_rgba(0,0,0,5%)] h-[72px] rounded-[8px] p-[15px] mt-[15px]">
                    <div className="flex flex-nowrap">
                      <div className="w-[50%]">
                        <p className="font-[500] text-[16px] leading-[40px] text-neutral900"><img src="/assets/images/sms-notification.svg" alt="" className="inline-block align-middle me-[5px]" />إستلام اشعارات علي المنصه</p>
                      </div>
                      <div className="w-[50%] text-end">
                        <div className="w-[184px] h-[40px] text-[16px] font-[500] leading-[40px] inline-block border border-neutral400 rounded-[4px] relative cursor-pointer overflow-hidden">
                          <span className={`w-[50%] h-[40px] leading-[40px] start-0 top-0 absolute text-center ${notificationStatus ? 'text-white bg-darkBlue' : 'text-neutral800 bg-white'}`} onClick={()=>setNotificationStatus(true)}>نعم</span>
                          <span className={`w-[50%] h-[40px] leading-[40px] end-0 top-0 absolute text-center ${!notificationStatus ? 'text-white bg-darkBlue' : 'text-neutral800 bg-white'}`} onClick={()=>setNotificationStatus(false)}>لا</span>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
              }
              </div>
            </div>
          </div>}
        </MainContent>
      </PageMain>

      { deleteUserModal && <ModalContainer heading="حذف حسابك" handleCloseModal={handleCloseModalDelete}>
            <p className="text-[16px] text-grey-900 leading-[24px] font-[400]">هل انت متاكد من حذف حسابك؟</p>
            <div className="text-end">
                <div className="text-grey-400 text-[18px] font-[600] inline-block cursor-pointer select-none me-[50px]" onClick={handleCloseModalDelete}>إلغاء</div>
                <NormalButton color={'!bg-danger'} label={'حذف الحساب'} onClick={handleClickDeleteUserAction} isDisabled={isAddUserFormDisabled} />
            </div>
        </ModalContainer> }


      <Toast toast={toast} setToast={setToast} />
      {!account && <Loading showLoading={true} />}
    </div>
  );
};

export default SettingsHomePage;
