export default function UserCard({ avatar="/assets/images/profile.svg", name, units, onClick }) {

    let finalAvatar;
    if(!avatar) {
        finalAvatar = "/assets/images/main-property-icon.svg";
    } else {
        finalAvatar = avatar;
    }

    return (
        <div className="h-[72px] rounded-[8px] overflow-hidden bg-grey-100 cursor-pointer" onClick={onClick}>
            <div className="inline-block align-middle w-[72px] h-[72px] bg-grey-200"><span className={`block w-full h-full !bg-center !bg-no-repeat ${ avatar ? '!bg-contain' : ''}`} style={{background:"url('"+finalAvatar+"')"}}></span></div>
            <div className="inline-block align-middle w-[188px] h-[72px] relative">
                <span className="w-[16px] h-[16px] absolute top-[15px] end-[10px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/arrow-left.svg')"}}></span>
                <div className="whitespace-nowrap overflow-hidden truncate pt-[10px] ps-[10px] pe-[30px] text-grey-900 text-[18px] font-[500]">{name}</div>
                <div className="whitespace-nowrap overflow-hidden truncate pt-[5px] px-[10px] text-grey-600 text-[12px] font-[500]">{units}</div>
            </div>
        </div>
    )

}