import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";

const MessagesHomePage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('الرسائل');
    
  return (
    <div>
    </div>
  );
};

export default MessagesHomePage;
