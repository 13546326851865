import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "../../../components/helpers/useDocumentTitle";
import PageHeader from "../../../components/shared/PageHeader";
import PageMain from "../../../containers/shared/PageMain";
import apiClient from "../../../components/utils/apiClient";
import MainContent from "../../../containers/shared/MainContent";
import NormalFormContainer from "../../../containers/shared/NormalFormContainer";
import InputField from "../../../components/shared/InputField";
import ModalContainer from "../../../containers/shared/ModalContainer";
import NormalButton from "../../../components/shared/NormalButton";
import TextArea from "../../../components/shared/TextArea";
import SingleImageCompress from "../../../components/helpers/SingleImageCompress";
import Loading from "../../../components/shared/Loading";
import Toast from "../../../components/shared/Toast";
import MapAutocomplete from "../../../components/shared/MapAutocomplete";
import CityConverter from "../../../components/helpers/CityConverter";


const UnitAddPage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('إنشاء وحدة جديدة');
    const { id } = useParams();
    const navigate = useNavigate();
    const pageTitle = {title: 'إنشاء وحدة جديدة', subTitle: 'يمكنك إنشاء وحدتك من هنا'};
    const [addUnitForm,setAddUnitForm] = useState({name: '', description: '', area: '', numOfRooms: '', addressDetails: {}, files: []});
    const [errors, setErrors] = useState(null);
    const [roleInfoModal, setRoleInfoModal] = useState(false);
    const [isAddUnitFormDisabled, setIsAddUnitFormDisabled] = useState(true);
    const [uploadPreview, setUploadPreview] = useState(null);
    const [compressedImage, setCompressedImage] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [UnitId, setUnitId] = useState(null);
    const [toast, setToast] = useState({show: false,type: null, message:'' });
    const [addressDetails, setAddressDetails] = useState(null);
    const [showNotSupportedCity, setShowNotSupportedCity] = useState(false);
  
    useEffect(()=>{
      if(addUnitForm && addUnitForm?.name && addUnitForm?.addressDetails) {
        setIsAddUnitFormDisabled(false);
      } else {
        setIsAddUnitFormDisabled(true);
      }
    },[addUnitForm]);
  
    const hideError = (err) => {
      if(errors) {
        let errorsList = errors;
        delete errorsList[err];
        setErrors(errorsList);
      }
    }


    const handleCloseModal = () => {
        setShowNotSupportedCity(false);
    }



    function handleChangeUpload(e) {
        SingleImageCompress(e.target.files[0]).then(compressedFile=>setCompressedImage(compressedFile));
        setUploadPreview(URL.createObjectURL(e.target.files[0]));
    }


    const handleSubmitAddUnit = () => {
        setShowLoading(true);
        setIsAddUnitFormDisabled(true);
        handleSubmitAddUnitAction();
    }

    const handleSubmitAddUnitAction = async () => {
        setErrors(null);
        const finalAddUnitForm = addUnitForm;
        finalAddUnitForm.addressDetails = addressDetails;
        finalAddUnitForm.propertyId = id;
        if(finalAddUnitForm?.addressDetails?.city) {
            finalAddUnitForm.addressDetails.city = CityConverter(finalAddUnitForm?.addressDetails?.city,'ar');
        }
        if((finalAddUnitForm?.addressDetails?.city && finalAddUnitForm?.addressDetails?.city !== 'notFound') || finalAddUnitForm?.addressDetails === null) {
            try {
                const response = await apiClient.post(`/b-units`,Object.fromEntries(Object.entries(finalAddUnitForm).filter(([_, v]) => v !== '')));
                if(response?.data) {
                    setShowLoading(false);
                    setUnitId(response?.data?._id);
                    setSuccessMessage(true);
                } else {
                    setIsAddUnitFormDisabled(false);
                    setShowLoading(false);
                }
                } catch(err) {
                    setIsAddUnitFormDisabled(false);
                    setShowLoading(false);
                    if(err?.response?.data?.errors) {
                        let errorsList = {};
                        err?.response?.data?.errors?.map(err=>{
                            errorsList[err.field] = err.message; 
                        });
                        setErrors(errorsList);
                    }
                }
        } else {
            setShowLoading(false);
            setShowNotSupportedCity(true);
            setIsAddUnitFormDisabled(false);
        }
    }
    

    const handleClickUnitDetails = () => {
        navigate(`/ar/unit/${UnitId}`);
      }

      


  return (
    <>
        <div>
        <PageHeader pageTitle={pageTitle} />
        <PageMain>
            <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
            <MainContent>
                <NormalFormContainer heading="من فضلك قم بملئ البيانات التالية لإنشاء وحدة جديدة." label="اضف وحدة جديدة">
                    
                    <div className="mb-[15px]">
                        <InputField label={'اسم الوحدة'} placeholder={'اكتب الاسم'} value={addUnitForm?.name} error={errors?.name} errorMessage={errors?.name} translateError={true} onChange={(e)=>{setAddUnitForm({ ...addUnitForm, name: e.target.value }); hideError('name')}} />
                    </div>

                    <div className="mb-[15px]">
                        <TextArea label={'وصف الوحدة'} placeholder={'مثال لنص يمكن استخدامة كوصف للوحدة'} value={addUnitForm?.description} error={errors?.description} errorMessage={errors?.description} translateError={true} onChange={(e)=>{ setAddUnitForm({ ...addUnitForm, description: e.target.value }); hideError('description')}} />
                    </div>

                    <div className="mb-[15px]">
                        <MapAutocomplete error={errors?.addressDetails} errorMessage={errors?.addressDetails} setAddressDetails={setAddressDetails} />
                    </div>

                    <div className="flex mb-[15px] gap-x-[16px]">
                        <div className="relative w-[50%]">
                            {addUnitForm?.area && addUnitForm?.area !== '' && <span className="font-[500] block text-grey-300 text-[16px] absolute top-[34px] end-[15px] z-[2]">متر مربع</span>}
                            <InputField label={'المساحة الكلية التقريبية (اختياري)'} placeholder={'اكتب المساحة الكلية التقريبية للوحدة'} value={addUnitForm?.area} error={errors?.area} errorMessage={errors?.area} translateError={true} onChange={(e)=>{ if(e.target.value === '' || (/^[0-9\b]+$/.test(e.target.value) && e.target.value > 0 )) { setAddUnitForm({ ...addUnitForm, area: e.target.value ? parseInt(e.target.value) : '' }) }; hideError('area')}} />
                        </div>
                        <div className="w-[50%]">
                            <InputField label={'عدد الغرف (اختياري)'} placeholder={'اكتب الغرف في الوحدة'} value={addUnitForm?.numOfRooms} error={errors?.numOfRooms} errorMessage={errors?.numOfRooms} translateError={true} onChange={(e)=>{ if(e.target.value === '' || (/^[0-9\b]+$/.test(e.target.value) && e.target.value > 0 )) { setAddUnitForm({ ...addUnitForm, numOfRooms: e.target.value ? parseInt(e.target.value) : '' }) }; hideError('numOfRooms')}} />
                        </div>
                    </div>

                    <div className="text-end">
                        <NormalButton label={'إنشاء وحدة'} onClick={handleSubmitAddUnit} isDisabled={isAddUnitFormDisabled} />
                    </div>

                </NormalFormContainer>
            </MainContent>
        </PageMain>
        </div>
        { successMessage && <ModalContainer hideX={true} maxWidth={'600px'} handleCloseModal={handleCloseModal}>
            <div className="text-center mb-[16px]">
                <span className="block w-[128px] h-[128px] m-auto !bg-center !bg-no-repeat" style={{background:"url('/assets/images/success.svg')"}} ></span>
            </div>
            <div className="text-center text-grey-900 mb-[80px]">
                <p className="font-[700] text-[30px] leading-[185%]">تهانينا !</p>
                <p className="font-[500] text-[24px] leading-[185%]">لقد قمت بإنشاء وحدتك بنجاح.</p>
            </div>
            <div className="text-center bg-grey-100 absolute bottom-0 left-0 w-full h-[96px] pt-[24px]">
                <NormalButton label={'الذهاب الي تفاصيل الوحدة'} onClick={handleClickUnitDetails} color={'!bg-grey-800'} />
            </div>
        </ModalContainer> }
        { showNotSupportedCity && <ModalContainer heading="حدث خطأ" handleCloseModal={handleCloseModal}>
            <div>
              عذرا, لقد اخترت مدينة خارج التغطية في الوقت الحالي.
            </div>
            <div className="text-end">
              <div className="text-grey-400 text-[18px] font-[600] inline-block cursor-pointer select-none" onClick={handleCloseModal}>حسنا</div>
            </div>
        </ModalContainer> }
        <Toast toast={toast} setToast={setToast} />
        <Loading showLoading={showLoading} />
    </>
  );
};

export default UnitAddPage;
