import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routesConfig from './routes';
import { useTranslation } from 'react-i18next';

const router = createBrowserRouter(routesConfig);

const App = () => {
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return <RouterProvider router={router} />;
};

export default App;
