import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import apiClient from "../../components/utils/apiClient";
import MainContent from "../../containers/shared/MainContent";
import NormalFormContainer from "../../containers/shared/NormalFormContainer";
import InputField from "../../components/shared/InputField";
import SelectField from "../../components/shared/SelectField";
import ModalContainer from "../../containers/shared/ModalContainer";
import NormalButton from "../../components/shared/NormalButton";
import MultiSelect from "../../components/shared/MultiSelect";
import FilterSelectedValues from "../../components/helpers/filterSelectedValues";
import getUniqueListByKey from "../../components/helpers/GetUniqueListByKey";

const UsersEditPage = () => {
    const {t,i18n} = useTranslation();
    const { id } = useParams();
    useDocumentTitle('تعديل حساب مستخدم ');
    const navigate = useNavigate();
    const pageTitle = {title: 'تعديل حساب مستخدم ', subTitle: 'يمكنك تعديل وحذف حساب المستخدمين.'};
    const [editUserForm,setEditUserForm] = useState({name: '', mobile: '', email:'', role: '', propertyIds: [], unitIds: [] });
    const [errors, setErrors] = useState(null);
    const [roleInfoModal, setRoleInfoModal] = useState(false);
    const [isAddUserFormDisabled, setIsAddUserFormDisabled] = useState(false);
    const [user,setUser] = useState(null);
    const [deleteUserModal, setDeleteUserModal] = useState(false);
    const [properties, setProperties] = useState(null);
    const [propertiesObjects, setPropertiesObjects] = useState(null);
    const [propertiesIds, setPropertiesIds] = useState([]);
    const [units, setUnits] = useState(null);
    const [getUnitsTrigger, setGetUnitsTrigger] = useState(0);
    const [selectedUnitsOptions, setSelectedUnitsOptions] = useState([])


    const getProperties = async () => {
        try {
          const response = await apiClient.get(`/b-properties`);
          if(response?.data) {
            setPropertiesObjects(response?.data?.properties);
            setProperties(response?.data?.properties?.map((property) => ({value: property?._id, label: property?.name}) ));
            setPropertiesIds(response?.data?.properties?.map(property =>property?._id));
          } else {
  
          }
        } catch(err) {}
      }

      const getUnits = (propertiesIds) => {
        const getUnits = [];
        propertiesObjects?.map((property) => {
            property?.units?.map((unit) => getUnits.push(unit));
        });
        setUnits(getUnits)
      }

      useEffect(()=>{
        if(Array.isArray(units) && units?.length > 0) {
            const newUnits = getUniqueListByKey(units,'_id');
            if(Array.isArray(newUnits) && newUnits?.length > 0) {
                setUnits(newUnits);
            }
        }
      },[getUnitsTrigger])
  
      useEffect(()=>{
        getProperties();
      },[]);

      useEffect(()=>{
        if(Array.isArray(propertiesIds) && propertiesIds?.length) {
            getUnits(propertiesIds);
        }
      },[propertiesIds]);

    const getUser = async () => {
        try {
          const response = await apiClient.get(`/b-users/${id}`);
          if(response?.data) {
            let data = response?.data;
            data.companyName = 'default';
            data.role = response?.data?.role?.id;
            data.status = 'active';
            data.propertyIds = response?.data?.properties?.map(property=>property?._id);
            data.unitIds = response?.data?.units?.map(unit=>unit?._id);
            setUser(data);
            setEditUserForm(data);
          } else {
  
          }
        } catch(err) {}
      }

      useEffect(()=>{
        if(user?.properties && Array.isArray(user?.properties) && user?.properties?.length >0) {
            handleChangePropertySelect(user?.properties?.map(property=>({label: property?.name, value:property?._id})))
        }
      },[user])
  
  
    const hideError = (err) => {
      if(errors) {
        let errorsList = errors;
        delete errorsList[err];
        setErrors(errorsList);
      }
    }

    const handleClickInfoIcon = () => {
        setRoleInfoModal(true);
    }
    const handleCloseModal = () => {
        setRoleInfoModal(false);
    }

    const handleCloseModalDelete = () => {
        setDeleteUserModal(false);
    }

    const handleSubmitEditUser = () => {
        setErrors(null);
        let errorsList = {};
        if(editUserForm?.name?.length < 2) {
            errorsList.name = 'validation.nameLength';
            }
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(editUserForm?.email)) {
            errorsList.email = 'validation.invalidEmail';
            }
            if(!/^(5)([0-9]{8})$/.test(editUserForm?.mobile?.replace('+966',''))) {
            errorsList.mobile = 'validation.invalidmobile';
            }
            if(Object.keys(errorsList).length > 0) {
            setErrors(errorsList);
        } else {
            handleClickSubmitEditUserAction();
        }
    }

    const handleClickSubmitEditUserAction = async () => {
        setIsAddUserFormDisabled(true);
        const finalEditUserForm = editUserForm;
        try {
        const response = await apiClient.put(`/b-users/${id}`,Object.fromEntries(Object.entries(finalEditUserForm).filter(([_, v]) => v !== '')));
        if(response?.status === 200) {
            navigate(`/ar/users`);
        } else {
            setIsAddUserFormDisabled(false);
        }
        } catch(err) {
            setIsAddUserFormDisabled(false);
            if(err?.response?.data?.errors) {
                let errorsList = {};
                err?.response?.data?.errors?.map(err=>{
                    errorsList[err.field] = err.message; 
                });
                setErrors(errorsList);
            }
        }
    }

    const handleSubmitDeleteUser = () => {
        setDeleteUserModal(true);
    }
    
    const handleSubmitDeleteUserAction = async () => {
        try {
            const response = await apiClient.delete(`/b-users/${id}`);
            if(response?.data) {
                navigate(`/ar/users`);
            } else {
                setIsAddUserFormDisabled(false);
            }
        } catch(err) {setIsAddUserFormDisabled(false);}
    }

    useEffect(()=>{
        getUser();
      },[]);


      const handleChangePropertySelect = (options) => {
        const IdsList = FilterSelectedValues(options);
        const newUnitsOptions = [];
        const newUnitsIds = [];
        IdsList?.map((id) => {
            units?.map((unit) => {if (unit?.propertyId === id) { newUnitsOptions.push({value: unit?._id, label: unit?.name}); newUnitsIds.push(unit?._id) }});
        });
        setSelectedUnitsOptions(newUnitsOptions);
        const newSelectedUnitsValues = editUserForm?.unitIds?.filter(unitId => newUnitsIds.includes(unitId));
        setEditUserForm({ ...editUserForm, unitIds: newSelectedUnitsValues, propertyIds: IdsList });
        hideError('propertyIds');
    }


  return (
    <>
        <div>
        <PageHeader pageTitle={pageTitle} />
        <PageMain>
            <MainContent>
                <NormalFormContainer heading="من فضلك قم بملئ البيانات التالية لتعديل حساب المستخدم." label="تعديل مستخدم">
                    
                    <div className="mb-[15px]">
                        <InputField label={t("forms.nameLabel")} placeholder={'اكتب اسم المستخدم هنا'} value={editUserForm?.name} error={errors?.name} errorMessage={errors?.name} translateError={true} onChange={(e)=>{setEditUserForm({ ...editUserForm, name: e.target.value }); hideError('name')}} />
                    </div>

                    <div className="mb-[15px]">
                        <InputField phone={true} label={t("forms.mobileLabel")} value={editUserForm?.mobile?.replace('+966','')} error={errors?.mobile} errorMessage={errors?.mobile} translateError={true} onChange={(e)=>{ if(e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) { setEditUserForm({ ...editUserForm, mobile: '+966'+e.target.value }) }; hideError('mobile')}} />
                    </div>

                    <div className="mb-[15px]">
                        <InputField label={'الايميل'} placeholder={'اكتب ايميلك'} value={editUserForm?.email} error={errors?.email} errorMessage={errors?.email} translateError={true} onChange={(e)=>{setEditUserForm({ ...editUserForm, email: e.target.value }); hideError('email')}} />
                    </div>

                    <div className="mb-[15px]">
                        <MultiSelect infoIcon={true} handleClickInfoIcon={handleClickInfoIcon} label={'إختار دور المستخدم'} placeholder={'اختر دور المستخدم والذي سيترتب عليه صلاحياته في استخدام النظام'} options={[{label:'المدير',value:'ADMINISTRATOR'},{label:'الموظف',value:'EMPLOYEE'}]} value={editUserForm?.role} onChange={(option)=>{setEditUserForm({ ...editUserForm, role: option.value }); hideError('role')}} />
                    </div>

                    <div className="mb-[15px]">
                        <MultiSelect isMulti label={'إختار المنشأة المعينة للمستخدم '} placeholder={'إسم المنشأة'} options={properties} error={errors?.propertyIds} errorMessage={errors?.propertyIds} value={editUserForm?.propertyIds} onChange={(options)=>{handleChangePropertySelect(options)}} />
                    </div>

                    { Array.isArray(editUserForm?.propertyIds) && editUserForm?.propertyIds?.length > 0 && <div className="mb-[15px]">
                        <MultiSelect isMulti label={'إختار الوحدة المعينة للمستخدم '} placeholder={'إسم الوحدة'} options={selectedUnitsOptions} error={errors?.unitIds} errorMessage={errors?.unitIds} value={editUserForm?.unitIds} onChange={(options)=>{setEditUserForm({ ...editUserForm, unitIds: FilterSelectedValues(options)});hideError('unitIds');}} />
                    </div>}

                    <div className="text-end">
                        <NormalButton color={'!bg-danger'} label={'حذف المستخدم'} onClick={handleSubmitDeleteUser} isDisabled={isAddUserFormDisabled} gabEnd={true} />
                        <NormalButton color={'!bg-grey-800'} label={'تحديث بيانات المستخدم'} onClick={handleSubmitEditUser} isDisabled={isAddUserFormDisabled} />
                    </div>

                </NormalFormContainer>
            </MainContent>
        </PageMain>
        </div>
        { roleInfoModal && <ModalContainer heading="ادوار المستخدمين" handleCloseModal={handleCloseModal}>
            <p className="bg-grey-100 rounded-[8px] p-[12px] mb-[20px]">
                <span className="inline-block bg-darkBlue text-white rounded-[23px] px-[8px] h-[32px] leading-[32px] mb-[10px] font-[600] text-[16px]">مالك حساب</span>
                <span className="block text-[12px] text-grey-900 font-[400] leading-[18px]">نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور </span>
            </p>
            <p className="bg-grey-100 rounded-[8px] p-[12px] mb-[20px]">
                <span className="inline-block bg-blue text-white rounded-[23px] px-[8px] h-[32px] leading-[32px] mb-[10px] font-[600] text-[16px]">المدير</span>
                <span className="block text-[12px] text-grey-900 font-[400] leading-[18px]">نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور </span>
            </p>
            <p className="bg-grey-100 rounded-[8px] p-[12px] mb-[20px]">
                <span className="inline-block bg-orange text-white rounded-[23px] px-[8px] h-[32px] leading-[32px] mb-[10px] font-[600] text-[16px]">الموظف</span>
                <span className="block text-[12px] text-grey-900 font-[400] leading-[18px]">نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور </span>
            </p>
            <div className="text-end">
                <NormalButton label={'اغلاق'} onClick={handleCloseModal} />
            </div>
        </ModalContainer> }

        { deleteUserModal && <ModalContainer heading="حذف حساب المستخدم" handleCloseModal={handleCloseModalDelete}>
            <p className="text-[16px] text-grey-900 leading-[24px] font-[400]">هل انت متاكد من حذف حساب المستخدم “{user?.name}”</p>
            <div className="text-end">
                <div className="text-grey-400 text-[18px] font-[600] inline-block cursor-pointer select-none me-[50px]" onClick={handleCloseModalDelete}>إلغاء</div>
                <NormalButton color={'!bg-danger'} label={'حذف الحساب'} onClick={handleSubmitDeleteUserAction} isDisabled={isAddUserFormDisabled} />
            </div>
        </ModalContainer> }
    </>
  );
};

export default UsersEditPage;
