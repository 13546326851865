import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "../../../components/helpers/useDocumentTitle";
import PageHeader from "../../../components/shared/PageHeader";
import PageMain from "../../../containers/shared/PageMain";
import apiClient from "../../../components/utils/apiClient";
import MainContent from "../../../containers/shared/MainContent";
import UnitBar from "../../../components/property/unit/UnitBar";
import UnitCard from "../../../components/property/unit/UnitCard";
import BoxContainer from "../../../containers/shared/BoxContainer";
import Button from "../../../components/shared/Button";
import CityConverter from "../../../components/helpers/CityConverter";
import RequestsBar from "../../../components/requests/RequestsBar";
import { FIELDS } from "../../../components/utils/constants";
import RequestsTable from "../../../components/requests/RequestsTable";

const SingleUnit = () => {
    const {t,i18n} = useTranslation();
    const { id } = useParams();
    const [unit, setUnit] = useState(null);
    useDocumentTitle(unit?.name);
    const navigate = useNavigate();
    const pageTitle = {title: unit?.name, subTitle: unit?.description};
    const [requests, setRequests] = useState(null);

    
    const getUnit = async () => {
        try {
          const response = await apiClient.get(`/b-units/${id}`);
          if(response?.data) {
            setUnit(response?.data);
            setRequests(response?.data?.subRequests);
          } else {
  
          }
        } catch(err) {}
      }

    useEffect(()=>{
        getUnit();
    },[])

    const handleClickEditUnit = () => {
      navigate(`/ar/unit/edit/${id}`);
    }



  return (
    <>
        {unit && <div>
        <PageHeader pageTitle={pageTitle} isColored />
        <PageMain>
            <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
            <MainContent>
                <BoxContainer label="تفاصيل الوحدة">
                    <div className="flex flex-nowrap justify-between self-stretch items-center gap-x-[16px] pb-[24px] mb-[24px] border-b-[1px] border-b-grey-300">
                        <div className="max-w-[70%]">
                            <h2 className="text-[24px] text-grey-900 font-[700] leading-[36px] mb-[5px]">{unit?.name && unit?.name}</h2>
                            <p className="text-[13px] text-grey-950 font-[500] leading-[20px] whitespace-nowrap truncate overflow-hidden">{unit?.description && unit?.description}</p>  
                        </div>
                        <div>
                            <div className="w-[324px]">
                                <Button text={'تعديل بيانات الوحدة'} bgColor={'!bg-grey-800'} onClick={()=>handleClickEditUnit()} />
                            </div>
                        </div>
                    </div>
                    <div className="bg-grey-50 rounded-[8px] px-[16px] py-[8px] text-grey-600 mb-[24px]">
                        <h4 className="mb-[4px] text-[24px] font-[500]">{unit?.addressDetails?.addressLine}</h4>
                        <p className="text-[14px] font-[500]"><span className="inline-block align-middle w-[16px] h-[16px] me-[4px] !bg-center !bg-no-repeat brightness-50" style={{background:"url('/assets/images/location.svg')", backgroundSize: "16px"}}></span> العنوان التفصيلي</p>
                    </div>
                    <div className="h-[61px] flex flex-nowrap">
                        <div className="text-[25px] font-[600] text-grey-900">
                            {unit?.area}
                            <span className="block mt-[4px] font-[500] text-[14px] text-grey-600">
                                <span className="w-[16px] h-[16px] inline-block align-middle !bg-center !bg-no-repeat me-[4px]" style={{background: "url('/assets/images/area.svg')"}}></span>
                                المساحة التقريبية
                            </span>
                        </div>
                        <div className="relative w-[193px] h-[61px]"><span className="block w-[1px] h-[40px] bg-grey-300 absolute top-[10px] left-[96px]"></span></div>
                        <div className="text-[25px] font-[600] text-grey-900">
                            {unit?.numOfRooms}
                            <span className="block mt-[4px] font-[500] text-[14px] text-grey-600">
                                <span className="w-[16px] h-[16px] inline-block align-middle !bg-center !bg-no-repeat me-[4px]" style={{background: "url('/assets/images/rooms.svg')"}}></span>
                                عدد الغرف
                            </span>
                        </div>
                        <div className="relative w-[193px] h-[61px]"><span className="block w-[1px] h-[40px] bg-grey-300 absolute top-[10px] left-[96px]"></span></div>
                        <div className="text-[25px] font-[600] text-grey-900">
                            {CityConverter(unit?.addressDetails?.city)}
                            <span className="block mt-[4px] font-[500] text-[14px] text-grey-600">
                                <span className="w-[16px] h-[16px] inline-block align-middle !bg-center !bg-no-repeat me-[4px]" style={{background: "url('/assets/images/city.svg')"}}></span>
                                المدينة
                            </span>
                        </div>
                        <div className="relative w-[193px] h-[61px]"><span className="block w-[1px] h-[40px] bg-grey-300 absolute top-[10px] left-[96px]"></span></div>
                        <div className="text-[25px] font-[600] text-grey-900">
                            {requests?.length}
                            <span className="block mt-[4px] font-[500] text-[14px] text-grey-600">
                                <span className="w-[16px] h-[16px] inline-block align-middle !bg-center !bg-no-repeat me-[4px] brightness-50" style={{background: "url('/assets/images/note.svg')"}}></span>
                                عدد الطلبات
                            </span>
                        </div>
                    </div>
                </BoxContainer>
                {requests !== null ? Array.isArray(requests) && requests?.length > 0 
                ? <div className="mt-[30px] pb-[30px]">
                <RequestsBar requestsCount={requests?.length} />
                <RequestsTable requests={requests} hidePropertyAndUnitNames={true} />
                </div>
                : <div className="pt-[100px] text-center">
                    <div className="inline-block w-[188px] h-[188px] !bg-contain !bg-center" style={{background: "url('/assets/images/note.svg')"}}></div>
                    <div className="w-full text-center text-grey-900 text-[24px] font-[500] mb-[25px]">لم تقم باي طلبات لهذة الوحدة الي الان</div>
                    <Link className="inline-block min-w-[320px] h-[48px] leading-[48px] rounded-[8px] text-white bg-grey-800 text-[18px] font-[600]" to={'/ar/requests/add'}>إنشاء طلب جديد</Link>
                </div> : ''}
            </MainContent>
        </PageMain>
        </div>}
    </>
  );
};

export default SingleUnit;
