import React, { useEffect, useState } from "react";
import { Outlet, Navigate, Link, useOutletContext } from "react-router-dom";
import useToken from "../components/helpers/useToken";
import MainNavItem from "../components/shared/MainNavItem";

const AppLayout = ({ children }) => {
  const [token, setToken] = useToken();
  const [navState, setNavState] = useState(true);


  return (
    token ? <div className="app-layout flex overflow-y-hidden h-screen w-screen">
      <div className={`w-[24px] h-[24px] cursor-pointer absolute top-[25px] z-[30] transition-all ${navState ? 'rotate-0 start-[217px]' : 'rotate-180 start-[99px]'}`} style={{background:"url('/assets/images/arrow-circle-right.svg')"}} onClick={()=>setNavState(!navState)}></div>
      <div className={`translate-x-full lg:-translate-x-0 fixed z-[21] flex flex-col flex-shrink-0 w-[229px] max-h-screen transition-all transform lg:z-auto lg:static ${navState ? 'lg:w-[229px]' : 'lg:w-[111px]'}`}>
        <div className="bg-darkBlue w-full h-screen overflow-y-auto">
          <Link to="/" className={`block bg-center bg-no-repeat h-[47px] mx-auto mt-[35px] ${navState ? 'w-[128px]' : 'w-[54px]'}`} style={{background: navState ? "url('/assets/images/logo-white.svg')" : "url('/assets/images/logo-mini.svg')"}}></Link>
          <nav className="pt-[35px]">
            {navState && <span className="text-[16px] font-[400] leading-[34px] text-grey-400 ps-[20px]">القائمة</span>}
            <ul className="p-0 m-0">
              <MainNavItem navStatus={navState} label={'الرئيسية'} icon={'main-home-icon.svg'} link={'/'} />
              <MainNavItem navStatus={navState} label={'الوحدات'} icon={'main-property-icon.svg'} link={'/ar/property'} />
              <MainNavItem navStatus={navState} label={'المستخدمين'} icon={'main-user-icon.svg'} link={'/ar/users'} />
              {/* <MainNavItem navStatus={navState} label={'الرسائل'} icon={'main-message-icon.svg'} link={'/ar/messages'} /> */}
              <MainNavItem navStatus={navState} label={'الطلبات'} icon={'main-request-icon.svg'} link={'/ar/requests'} />
              {/* <MainNavItem navStatus={navState} label={'الصيانة الوقائية'} icon={'main-maintenance-icon.svg'} link={'/ar/maintenance'} /> */}
            </ul>
            {navState && <span className="text-[16px] font-[400] leading-[34px] text-grey-300 ps-[20px] relative mt-[45px] block"><i className="absolute block w-[100px] h-[1px] top-[-10px] start-[20px] bg-grey-300"></i>المساعدة والدعم</span>}
            <ul className="p-0 m-0">
              {/* <MainNavItem navStatus={navState} label={'مركز المساعدة'} icon={'main-help-icon.svg'} link={'/ar/help'} grey300={true} /> */}
              <MainNavItem navStatus={navState} label={'الأعدادات'} icon={'main-settings-icon.svg'} link={'/ar/settings'} grey300={true} />
              <MainNavItem navStatus={navState} label={'تسجييل الخروج'} icon={'logout.svg'} link={'#!'} grey300={true} disableArrow={true} type={'logout'} />
            </ul>
          </nav>
        </div>
      </div>
      <div className="flex flex-col flex-1 h-full overflow-y-auto">
        <Outlet context={{navState}}/> 
      </div>
    </div> : <Navigate to="/ar/login"/>
  );
};

export function useNavState() {
  return useOutletContext();
}

export default AppLayout;
