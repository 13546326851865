import { useEffect, useState } from "react";

export default function useToken(key='token') {
    const [token, setToken] = useState(() => {
      try {
        const storedToken = window.localStorage.getItem(key);
        return storedToken && storedToken !== undefined && storedToken !== 'undefined' ? storedToken : undefined;
      } catch (error) {
        console.error(error);
        return undefined;
      }
    });
  
    useEffect(() => {
      try {
        if(token !== 'remove') {
            window.localStorage.setItem(key, token);
        } else {
            window.localStorage.removeItem(key);
        }
      } catch (error) {
        console.error(error);
      }
    }, [key, token]);
  
    return [token, setToken];
  }