import React, { useEffect, useState } from "react";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import { useTranslation } from "react-i18next";
import InputField from "../../components/shared/InputField";
import Button from "../../components/shared/Button";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../components/utils/apiClient";
import useToken from "../../components/helpers/useToken";
import useUser from "../../components/helpers/useUser";
import { OtpInput } from 'reactjs-otp-input';
import OTPInputField from "../../components/shared/OTPInputField";

const LoginPage = () => {

  const {i18n,t} = useTranslation();
  useDocumentTitle('pageTitles.login');
  const navigate = useNavigate();
  const [isFormSubmitDisabled,setIsFormSubmitDisabled] = useState(true);
  const [signInForm,setSignInForm] = useState({mobile:'', OTP: ''});
  const [errors, setErrors] = useState(null);
  const [showOTP, setShowOTP] = useState(false);
  const [token, setToken] = useToken();
  const [user, setUser] = useUser();

  useEffect(()=>{
    if(signInForm && signInForm?.mobile) {
      setIsFormSubmitDisabled(false);
    } else {
      setIsFormSubmitDisabled(true);
    }
  },[signInForm]);

  const hideError = (err) => {
    if(errors) {
      let errorsList = errors;
      delete errorsList[err];
      setErrors(errorsList);
    }
  }

  const handleClickSignInButton = () => {
    setErrors(null);
    let errorsList = {};
    // if(signInForm?.password?.length !== 4) {
    //   errorsList.password = 'validation.passwordLength';
    // }
    if(!/^(5)([0-9]{8})$/.test(signInForm?.mobile?.replace('+966',''))) {
      errorsList.mobile = 'validation.invalidmobile';
    }
    if(Object.keys(errorsList).length > 0) {
      setErrors(errorsList);
    } else {
      handleClickSignInButtonAction();
    }
  }

  const handleClickSignInButtonAction = async () => {
    setIsFormSubmitDisabled(true);
    try {
      const response = await apiClient.post(`/b-users/one-time-password-login`,Object.fromEntries(Object.entries(signInForm).filter(([_, v]) => v !== '')));
      if(response?.status == 204) {
        setShowOTP(true);
      } else {
        setIsFormSubmitDisabled(false);
      }
    } catch(err) {
      setIsFormSubmitDisabled(false);
      if(err?.response?.data && err?.response?.data?.category === 'UserNotFound') {
        let errorsList = {};
        errorsList.mobile = 'لايوجد مستخدم مسجل بهذا الرقم';
        setErrors(errorsList)
      }
    }
  }


  const handleClickSignInButton2 = () => {
    setErrors(null);
    let errorsList = {};
    // if(signInForm?.password?.length !== 4) {
    //   errorsList.password = 'validation.passwordLength';
    // }
    if(!/^[0-9]{4}$/.test(signInForm?.OTP)) {
      errorsList.OTP = 'validation.invalidOTP';
    }
    if(Object.keys(errorsList).length > 0) {
      setErrors(errorsList);
    } else {
      handleClickSignInButtonAction2();
    }
  }

  const handleClickSignInButtonAction2 = async () => {
    setIsFormSubmitDisabled(true);
    try {
      const response = await apiClient.post(`/b-users/login`,Object.fromEntries(Object.entries(signInForm).filter(([_, v]) => v !== '')));
      if(response?.status == 200) {
        setToken(response?.data?.accessToken);
        const getUser = response?.data?.user;
        getUser.username = signInForm?.mobile.replace('+966','966');
        setUser(getUser);
      } else {
        setIsFormSubmitDisabled(false);
      }
    } catch(err) {
      setIsFormSubmitDisabled(false);
      if(err?.response?.data && err?.response?.data?.category === 'INVALID_CREDENTIALS') {
        let errorsList = {};
        errorsList.OTP = 'الكود غير صحيح';
        setErrors(errorsList)
      } else {
        let errorsList = {};
        errorsList.OTP =err?.response?.data?.message;
        setErrors(errorsList);
      }
    }
  }

  useEffect(()=>{
    if(token !== undefined && token !== 'undefined' && token !== 'remove') {
      navigate(`/${i18n.language}/`);
    }
  },[token])

  return (
    <div className="w-full">
      <h3 className="text-grey-900 font-[600] text-[32px] leading-[48px]">{t('loginPage.welcome')}</h3>
      <p className="text-grey-600 font-[500] text-[18px] leading-[27px] mt-[15px] mb-[40px]">{t('loginPage.subHead')}</p>
      <p className="text-grey-900 font-[500] text-[18px] leading-[27px]">{t('loginPage.formHead')}</p>
      <div className="w-full max-w-[450px] pt-[40px]">
        {!showOTP ?
        <>
          <div className="mb-[20px]">
            <InputField onkeydown={(e)=>e.key === 'Enter' && (!isFormSubmitDisabled) && handleClickSignInButton() } phone={true} label={t("forms.mobileLabel")} value={signInForm?.mobile?.replace('+966','')} error={errors?.mobile} errorMessage={errors?.mobile} translateError={true} onChange={(e)=>{ if(e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) { setSignInForm({ ...signInForm, mobile: '+966'+e.target.value }) }; hideError('mobile')}} />
          </div>
          <div className="mb-[35px]">
            <Button type="button" text={t('forms.signin')} disabled={isFormSubmitDisabled} onClick={()=>handleClickSignInButton()} />
          </div>
        </>
        :
        <>
          <div className="mb-[20px]">
            <OTPInputField onkeydown={(e)=>e.key === 'Enter' && (!isFormSubmitDisabled) && handleClickSignInButton2() } label={t("forms.OTPLabel")} value={signInForm?.OTP} error={errors?.OTP} errorMessage={errors?.OTP} translateError={true} onChange={(otp)=>{ if(otp === '' || /^[0-9\b]+$/.test(otp)) { setSignInForm({ ...signInForm, OTP: otp }) }; hideError('OTP')}} />
          </div>
          <div className="mb-[35px]">
            <Button type="button" text={t('forms.signin')} disabled={isFormSubmitDisabled} onClick={()=>handleClickSignInButton2()} />
          </div>
        </>}
        
        <p className="text-grey-600 text-[18px] font-[500] leading-[27px]">{t('loginPage.signupText')} <Link className="text-blue font-[700]" to={`/${i18n.language}/register`}>{t('loginPage.signup')}</Link>.</p>
      </div>
    </div>
  );
};

export default LoginPage;
