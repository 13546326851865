import { useNavigate } from "react-router-dom";
import { FIELDS } from "../utils/constants"
import { useState } from "react";
import RequestModal from "../../containers/shared/RequestModal";
import GetRequestStyle from "../helpers/GetRequestStyle";
import GetServiceByCode from "../helpers/GetServiceByCode";

export default function RequestsTable({ requests, hidePropertyAndUnitNames=false, type="sub" }) {

    const navigate = useNavigate();

    const [selectedRequest, setSelectedRequest] = useState();
    const [showRequestDetails, setShowRequestDetails] = useState(false);

    const sortBySoonest = (list) => {
        const currentDate = new Date()
        return list.sort(function (a, b) {
            let dateA = new Date(a.startWorkingDate)
            let dateB = new Date(b.startWorkingDate)
            if (dateA > currentDate || dateB > currentDate) {
                return -1;
            }
            return dateA - dateB;
        });
    }

    const getStartWorkingDate = (request) => {
        if(request?.startWorkingDate) {
            return new Date(request?.startWorkingDate).toLocaleDateString("ar-eg", {year: 'numeric', month: 'long', day: 'numeric'});
        } else {
            if(request?.units && Array.isArray(request?.units) && request?.units?.length > 0 && request?.units[0]?.startWorkingDate) {
                let sortedRequests;
                if(request?.units?.length > 1) {
                    sortedRequests = sortBySoonest(request?.units);
                } else {
                    sortedRequests = request?.units;
                }
                return new Date(sortedRequests[0].startWorkingDate).toLocaleDateString("ar-eg", {year: 'numeric', month: 'long', day: 'numeric'});
            } else if(request?.subRequests && Array.isArray(request?.subRequests) && request?.subRequests?.length > 0 && request?.subRequests[0]?.startWorkingDate) {
                let sortedRequests;
                if(request?.subRequests?.length > 1) {
                    sortedRequests = sortBySoonest(request?.subRequests);
                } else {
                    sortedRequests = request?.subRequests;
                }
                return new Date(sortedRequests[0].startWorkingDate).toLocaleDateString("ar-eg", {year: 'numeric', month: 'long', day: 'numeric'});
            } else {
                return 'غير محدد';
            }
        }
    }

    const getUnitsNames = (request) => {
        if(request?.unitName) {
            return request?.unitName;
        } else {
            if(request?.units && Array.isArray(request?.units) && request?.units?.length > 0 && request?.units[0]?.unitName) {
                if(request?.units?.length > 1) {
                    let unitsNames = [];
                    let unitsText;
                    request.units?.map((unit, index) => {
                        unitsNames = [...unitsNames, unit?.unitName];
                    });
                    [...new Set(unitsNames)].map((p,i)=> i===0 ? unitsText = ''+p : unitsText += ' + ' + p);
                    return unitsText;
                } else {
                    return request?.units[0]?.unitName;
                }
            } else if(request?.subRequests && Array.isArray(request?.subRequests) && request?.subRequests?.length > 0 && request?.subRequests[0]?.unitName) {
                if(request?.subRequests?.length > 1) {
                    let unitsNames = [];
                    let unitsText;
                    request.subRequests?.map((sub, index) => {
                        unitsNames = [...unitsNames, sub?.unitName];
                    });
                    [...new Set(unitsNames)].map((p,i)=> i===0 ? unitsText = ''+p : unitsText += ' + ' + p);
                    return unitsText;
                } else {
                    return request?.subRequests[0]?.unitName;
                }
            } else {
                return 'غير محدد';
            }
        }
    }

    const getPropertiesNames = (request) => {
        if(request?.propertyName) {
            return request?.propertyName;
        } else {
            if(request?.units && Array.isArray(request?.units) && request?.units?.length > 0 && request?.units[0]?.propertyName) {
                if(request?.units?.length > 1) {
                    let propertiesNames = [];
                    let propertiesText;
                    request.units?.map((unit, index) => {
                        propertiesNames = [...propertiesNames, unit?.propertyName];
                    });
                    [...new Set(propertiesNames)].map((p,i)=> i===0 ? propertiesText = ''+p : propertiesText += ' + ' + p);
                    return propertiesText;
                } else {
                    return request?.units[0]?.propertyName;
                }
            } else if(request?.subRequests && Array.isArray(request?.subRequests) && request?.subRequests?.length > 0 && request?.subRequests[0]?.propertyName) {
                if(request?.subRequests?.length > 1) {
                    let propertiesNames = [];
                    let propertiesText;
                    request.subRequests?.map((sub, index) => {
                        propertiesNames = [...propertiesNames, sub?.propertyName];
                    });
                    [...new Set(propertiesNames)].map((p,i)=> i===0 ? propertiesText = ''+p : propertiesText += ' + ' + p);
                    return propertiesText;
                } else {
                    return request?.subRequests[0]?.propertyName;
                } 
            } else {
                return 'غير محدد';
            }
        }
    }

    const getFieldName = (request) => {
        if(request?.field?.name?.indexOf("'") > -1) {
            return request?.field?.name?.split("'")[1];
        } else {
            if(request?.field?.name) {
                return request?.field?.name;
            } else {
                return request?.field;
            }
        }
    }


    requests.sort((a, b) => {
        if (a.prettyId < b.prettyId) return 1;
        if (a.prettyId > b.prettyId) return -1;
        return 0;
      });
      

    const handleClickRequestRow = (request) => {
        if(type === 'sub') {
            navigate(`/ar/subRequest/${request?._id}`);
        } else {
        if(request?.subRequests && Array.isArray(request?.subRequests) && request?.subRequests?.length > 0) {
            if(request?.subRequests?.length > 1 || request?.subRequests[0]?.initialWorkList?.length > 1) {
                navigate(`/ar/requests/${request?._id}`);
            } else {
                const subrequest = request?.subRequests[0];
                subrequest.statuses = request?.statuses;
                subrequest.prettyId = request?.prettyId;
                subrequest.field = request?.field;
                subrequest.invoiceDetails = request?.invoiceDetails;
                subrequest.status = request?.status;
                setSelectedRequest(subrequest);
                setShowRequestDetails(true);
            }
        }
    }
    }

    const handleClickShowPopup = (request) => {
        // setSelectedRequest(request);
        // setShowRequestDetails(true);
    }

    const handleCloseModal = () => {
        setShowRequestDetails(false);
    }


    return (
        <>
        <div className="pb-[30px]">
            <table className="w-full mt-[30px]" dir="rtl">
                <thead>
                <tr className="border-b-[2px] border-b-grey-50">
                    <th className="bg-darkBlue text-white leading-[56px] rounded-tr-[8px] text-start px-[15px] text-[16px] font-[500px] border-l-[1px] border-l-grey-100">#</th>
                    <th className="bg-darkBlue text-white leading-[56px] text-start px-[15px] text-[16px] font-[500px] border-l-[1px] border-l-grey-100">رقم الطلب</th>
                    <th className="bg-darkBlue text-white leading-[56px] text-start px-[15px] text-[16px] font-[500px] border-l-[1px] border-l-grey-100">تاريخ البدء</th>
                    <th className="bg-darkBlue text-white leading-[56px] text-start px-[15px] text-[16px] font-[500px] border-l-[1px] border-l-grey-100">انشئ بواسطة</th>
                    {!hidePropertyAndUnitNames && <><th className="bg-darkBlue text-white leading-[56px] text-start px-[15px] text-[16px] font-[500px] border-l-[1px] border-l-grey-100">المنشأة</th>
                    <th className="bg-darkBlue text-white leading-[56px] text-start px-[15px] text-[16px] font-[500px] border-l-[1px] border-l-grey-100">الوحدة</th></>}
                    <th className="bg-darkBlue text-white leading-[56px] text-start px-[15px] text-[16px] font-[500px] border-l-[1px] border-l-grey-100">الخدمة</th>
                    <th className="bg-darkBlue text-white leading-[56px] text-start px-[15px] text-[16px] font-[500px] border-l-[1px] border-l-grey-100">التكلفة</th>
                    <th className="bg-darkBlue text-white leading-[56px] text-start px-[15px] text-[16px] font-[500px] border-l-[1px] border-l-grey-100">حالة الطلب</th>
                    <th className="bg-darkBlue text-white leading-[56px] rounded-tl-[8px] text-start px-[15px] text-[16px] font-[500px]"></th>
                </tr>
                </thead>
                <tbody>
                {requests.map((request, index) => {;
                    return (
                    <tr key={`requests-${index}`} className={`${index !== requests?.length-1 && 'border-b-[1px] border-b-grey-200'}`}>
                        <td className={`bg-white h-[64px] pt-[14px] px-[15px] text-grey-600 font-[500] text-[14px] whitespace-nowrap w-[52px] cursor-pointer ${index === requests?.length-1 && 'rounded-br-[8px]'}`} onClick={()=>handleClickRequestRow(request)}>{index+1}</td>
                        <td className={`bg-white h-[64px] pt-[14px] px-[15px] text-grey-600 font-[500] text-[14px] whitespace-nowrap cursor-pointer`} onClick={()=>handleClickRequestRow(request)}>{request?.prettyId}</td>
                        <td className={`bg-white h-[64px] pt-[14px] px-[15px] text-grey-600 font-[500] text-[14px] whitespace-nowrap cursor-pointer`} onClick={()=>handleClickRequestRow(request)}>{getStartWorkingDate(request)}</td>
                        <td className={`bg-white h-[64px] pt-[14px] px-[15px] text-grey-600 font-[500] text-[14px] cursor-pointer`} onClick={()=>handleClickRequestRow(request)}>{request?.statuses[0]?.createdBy?.name}</td>
                        {!hidePropertyAndUnitNames && <><td className={`bg-white h-[64px] pt-[14px] px-[15px] text-grey-600 font-[500] text-[14px] cursor-pointer`} onClick={()=>handleClickRequestRow(request)}>{getPropertiesNames(request)}</td>
                        <td className={`bg-white h-[64px] pt-[14px] px-[15px] text-grey-600 font-[500] text-[14px] cursor-pointer`} onClick={()=>handleClickRequestRow(request)}>{getUnitsNames(request)}</td></>}
                        <td className={`bg-white h-[64px] pt-[14px] px-[15px] text-grey-600 font-[500] text-[14px] !text-right whitespace-nowrap cursor-pointer`} onClick={()=>handleClickRequestRow(request)}>{FIELDS[getFieldName(request)]?.ar || 'غير معرف'}</td>
                        <td className={`bg-white h-[64px] pt-[14px] px-[15px] text-grey-600 font-[500] text-[14px] !text-right whitespace-nowrap cursor-pointer`} onClick={()=>handleClickRequestRow(request)}><span className="font-[600]" dir="ltr">{request?.invoiceDetails?.total} sr</span></td>
                        <td className={`bg-white h-[64px] pt-[14px] px-[15px] text-grey-600 font-[500] text-[14px] !text-right whitespace-nowrap cursor-pointer`} onClick={()=>handleClickRequestRow(request)}><span className={`font-[600] ${GetRequestStyle(request?.status,'color')}`}>{GetRequestStyle(request?.status)}</span></td>
                        <td className={`bg-white h-[64px] pt-[14px] px-[15px] text-grey-600 font-[500] text-[14px] !text-right whitespace-nowrap w-[48px] ${index === requests?.length-1 && 'rounded-bl-[8px]'}`} onClick={()=>handleClickShowPopup(request)}><div className="cursor-pointer select-none w-[16px] h-[16px] !bg-center !bg-contain" style={{background:"url('/assets/images/more.svg')"}}></div></td>
                    </tr>
                    )
                })}
                </tbody>
            </table> 
        </div>

          { showRequestDetails && <RequestModal bgColor={GetRequestStyle(selectedRequest?.status,'bg')} heading={GetRequestStyle(selectedRequest?.status,'heading')} handleCloseModal={handleCloseModal}>
            <div className="">
                <div className="text-[14px] text-grey-600 font-[500] h-[30px] leading-[30px] my-[10px] px-[16px]">
                    <span className="text-[12px] text-grey-900 font-[600]">رقم الطلب:</span> {selectedRequest?.prettyId}
                </div>
                <div className="text-[14px] text-grey-600 font-[500] h-[30px] leading-[30px] my-[10px] mx-[16px] border-t-[1px] border-t-grey-200">
                    <span className="text-[12px] text-grey-900 font-[600]">تاريخ البدء:</span> {new Date(selectedRequest?.startWorkingDate).toLocaleDateString("ar-eg", {year: 'numeric', month: 'long', day: 'numeric'})}
                </div>
                <div className="text-[14px] text-grey-600 font-[500] h-[30px] leading-[30px] my-[10px] mx-[16px] border-t-[1px] border-t-grey-200">
                    <span className="text-[12px] text-grey-900 font-[600]">انشئ بواسطة:</span> {selectedRequest?.statuses[0]?.createdBy?.name}
                </div>
                <div className="text-[14px] text-grey-600 font-[500] h-[30px] leading-[30px] my-[10px] mx-[16px] border-t-[1px] border-t-grey-200">
                    <span className="text-[12px] text-grey-900 font-[600]">المنشأة:</span> {selectedRequest?.propertyName}
                </div>
                <div className="text-[14px] text-grey-600 font-[500] h-[30px] leading-[30px] my-[10px] mx-[16px] border-t-[1px] border-t-grey-200">
                    <span className="text-[12px] text-grey-900 font-[600]">الوحدة:</span> {selectedRequest?.unitName}
                </div>
            </div>
            <div  className="p-[16px] bg-grey-50 my-[10px] border-t-[1px] border-t-grey-200 border-b-[1px] border-b-grey-200">
                <div className="flex flex-nowrap mb-[10px]">
                    <div className="w-[50%] text-[16px] text-grey-900 font-[600]">تفاصيل الخدمه</div>
                    <div className="w-[50%] text-end"></div>
                </div>
                <div className="flex flex-nowrap gap-x-[10px] mb-[15px]">
                    <div className="w-full max-w-[228px]">
                        <div className="inline-block align-middle w-[32px] h-[32px] bg-[#9E9E9E] me-[10px]" style={{maskImage:"url('/assets/images/"+FIELDS[getFieldName(selectedRequest)]?.icon+"')", maskSize: "contain", maskRepeat: "no-repeat"}}></div>
                        <div className="inline-block align-middle">
                            <p className="text-[#616161]">الخدمة الأساسية</p>
                            <p className="text-[#212121] text-[16px] font-[600]">{FIELDS[getFieldName(selectedRequest)]?.ar}</p>
                        </div>
                    </div>
                    <div className="w-full max-w-[180px]">
                        <p className="text-[#616161]">نوع الخدمة</p>
                        <p className="text-[#212121] text-[16px] font-[600]">{GetServiceByCode(getFieldName(selectedRequest), selectedRequest?.initialWorkList ? selectedRequest?.initialWorkList[0]?.maintenanceCode : selectedRequest?.serviceDetails?.subServiceCode)[0]}</p>
                    </div>
                    <div className="w-full max-w-[280px]">
                        <p className="text-[#616161]">أسم الخدمة الفرعية</p>
                        <p className="text-[#212121] text-[16px] font-[600]">{GetServiceByCode(getFieldName(selectedRequest), selectedRequest?.initialWorkList ? selectedRequest?.initialWorkList[0]?.maintenanceCode : selectedRequest?.serviceDetails?.subServiceCode)[1]}</p>
                    </div>
                    <div className="w-full max-w-[280px]">
                        <p className="text-[#616161]">الكمية</p>
                        <p className="text-[#212121] text-[16px] font-[600]">{selectedRequest?.initialWorkList ? selectedRequest?.initialWorkList[0]?.amount : 1}</p>
                    </div>
                </div>
                <div className="flex flex-nowrap mb-[10px]">
                    <div className="w-full text-[16px] text-grey-900 font-[600]">الوحدات</div>
                </div>
                <div>
                    <div className="inline-block align-middle px-[8px] rounded-[8px] h-[32px] leading-[32px] text-grey-900 font-[500] text-[16px] bg-[rgba(30,41,59,0.1)] me-[10px]">
                        {selectedRequest?.unitName}
                    </div>
                </div>
            </div>
            <div className="p-[16px]">
                <p className="mb-[16px] font-[600] text-[16px] text-grey-900">تفاصيل الفاتورة</p>
                <div className="border-t-[1px] border-dotted border-grey-300">
                    <div className="flex flex-nowrap my-[10px] leading-[30px] h-[30px]">
                        <div className="w-[50%] text-[16px] text-grey-600 font-[500]">اجمالي قطع الغيار المطلوبة</div>
                        <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{selectedRequest?.invoiceDetails?.partsCost?.toFixed(2)} sr</div>
                    </div>
                    <div className="flex flex-nowrap my-[10px] leading-[30px] h-[30px]">
                        <div className="w-[50%] text-[16px] text-grey-600 font-[500]">اجمالي راتب العمالة</div>
                        <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{selectedRequest?.invoiceDetails?.workerWage ? selectedRequest?.invoiceDetails?.workerWage?.toFixed(2) : selectedRequest?.invoiceDetails?.workersWage?.toFixed(2)} sr</div>
                    </div>
                </div>
                <div className="border-t-[1px] border-dotted border-grey-300">
                    <div className="flex flex-nowrap my-[10px] leading-[30px] h-[30px]">
                        <div className="w-[50%] text-[16px] text-grey-600 font-[500]">المجموع</div>
                        <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{selectedRequest?.invoiceDetails?.workerWage ? Number(selectedRequest?.invoiceDetails?.workerWage + selectedRequest?.invoiceDetails?.partsCost)?.toFixed(2) : Number(selectedRequest?.invoiceDetails?.workersWage + selectedRequest?.invoiceDetails?.partsCost)?.toFixed(2)} sr</div>
                    </div>
                    <div className="flex flex-nowrap my-[10px] leading-[30px] h-[30px]">
                        <div className="w-[50%] text-[16px] text-grey-600 font-[500]">الضريبة</div>
                        <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{selectedRequest?.invoiceDetails?.vatValue?.toFixed(2)} sr</div>
                    </div>
                </div>
                <div className="border-t-[1px] border-dotted border-grey-300">
                    <div className="flex flex-nowrap mt-[10px] leading-[30px] h-[30px]">
                        <div className="w-[50%] text-[16px] text-grey-600 font-[500]">اجمالي ما سيتم دفعه</div>
                        <div className="w-[50%] text-[16px] text-grey-900 font-[500]" dir="ltr">{selectedRequest?.invoiceDetails?.total?.toFixed(2)} sr</div>
                    </div>
                </div>
            </div>
        </RequestModal> }

        </>
    )
}

