export default function SelectBoxContainer({children, ...props}) {
    
    
    return (
        <div className="mb-[15px]">
        <div style={{maxWidth: `${props?.maxWidth ? props?.maxWidth : '100%' }`}}>
            <div className="flex flex-nowrap h-[56px] leading-[56px] bg-grey-100 text-grey-900 font-[600] text-[16px] px-[16px] rounded-t-[8px]">
                <div className={`w-[50%] !text-[18px] !font-[600] ${props?.selectStatus ? 'text-blue' : 'text-grey-900' }`}>{props?.label} <span className="text-orange">({props?.count} وحدات)</span></div>
                <div className="w-[50%] text-end select-none"><div className={`inline-block cursor-pointer whitespace-nowrap ${props?.selectStatus ? 'text-blue' : 'text-grey-900'}`} onClick={()=>props?.handleClickAllButton()}>{props?.selectStatus ? <span className="w-[20px] h-[20px] inline-block align-middle !bg-center !bg-no-repeat me-[5px]" style={{background: "url('/assets/images/check-mark.svg')"}}></span> : <span className="w-[16px] h-[16px] inline-block align-middle border-[1px] border-circle rounded-full me-[5px]"></span>}{props?.allText}</div></div>
            </div>
            <div className="p-[24px] rounded-b-[8px] border-[1px] border-grey-200 border-t-0 bg-white">
                <div className="flex flex-wrap gap-[8px]">
                    {children}
                </div>
            </div>
        </div>
        </div>
    )
}