import React, { useState } from "react";
import LangSwithcher from "../components/helpers/LangSwithcher";
import { Link, Outlet, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination, A11y,  } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import useToken from "../components/helpers/useToken";

const AuthLayout = ({ children }) => {
  const {t,i18n} = useTranslation();
  const [token, setToken] = useToken();

  return (
  <div className="auth-layout flex flex-nowrap">
        <div className="w-full lg:w-[50%] min-h-[100vh] relative">
          <div className="absolute w-[calc(100%-48px)] top-[24px] start-[24px] flex flex-nowrap">
            <div className="w-[50%] h-[47px]">
              <Link to="/"><img src="/assets/images/logo-normal.svg" className="w-[128px] h-[47px]" alt="" /></Link>
            </div>
            <div className="w-[50%] h-[47px] text-end">
              {/* <LangSwithcher style="auth" /> */}
            </div>
          </div>
            <div className="flex w-full min-h-[100vh] p-[24px] items-center pt-[90px]">
            {!token ? <Outlet/> : <Navigate to="/ar/dashboard"/>}
            </div>
        </div>
        <div className="hidden lg:block w-[50%] min-h-[100vh] bg-darkBlue">
          <div className="authActions pt-[calc(50vh-175px)]">
          <div className="auth_slider">
            <div className="slider">
              <div className="slide-track gap-x-[16px]">
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white text-center pt-[16px]">
                    <div style={{background:"url('/assets/images/Carpentry.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.carpentry")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/Painting.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.painting")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/Electricity.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.electricity")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/Plumping.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.plumping")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/CCTV.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.cctv")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white text-center pt-[16px]">
                    <div style={{background:"url('/assets/images/Carpentry.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.carpentry")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/Painting.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.painting")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/Electricity.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.electricity")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/Plumping.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.plumping")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/CCTV.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.cctv")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white text-center pt-[16px]">
                    <div style={{background:"url('/assets/images/Carpentry.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.carpentry")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/Painting.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.painting")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/Electricity.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.electricity")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/Plumping.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.plumping")}</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="w-[134px] h-[134px] rounded-[16px] bg-white pt-[16px]">
                    <div style={{background:"url('/assets/images/CCTV.svg')"}} className="w-[64px] h-[64px] !bg-center !bg-no-repeat !m-auto !mb-[10px]"></div>
                    <div className="text-center text-blue font-[600] text-[18px]">{t("services.cctv")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper_box p-[25px] mt-[20px]" dir={'ltr'}>
            <Swiper
              dir={'ltr'}
              style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
                "--swiper-pagination-bullet-horizontal-gap": "1px",
                "--swiper-pagination-bottom": "0px"
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Navigation, Pagination, A11y]}
              spaceBetween={0}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="text-center text-white">
                  <h2 className="font-[700] text-[32px] mb-[20px] px-[45px]">{t('authSlider.head1')}</h2>
                  <p className="font-[400] text-[22px] px-[45px] pb-[45px]">{t('authSlider.body1')}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="text-center text-white">
                  <h2 className="font-[700] text-[32px] mb-[20px] px-[45px]">{t('authSlider.head1')}</h2>
                  <p className="font-[400] text-[22px] px-[45px] pb-[45px]">{t('authSlider.body1')}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="text-center text-white">
                  <h2 className="font-[700] text-[32px] mb-[20px] px-[45px]">{t('authSlider.head1')}</h2>
                  <p className="font-[400] text-[22px] px-[45px] pb-[45px]">{t('authSlider.body1')}</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          </div>
        </div>
    </div>)
  };

export default AuthLayout;
