import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import useToken from "../components/helpers/useToken";

const EmptyLayout = ({ children }) => {
  const [token, setToken] = useToken();

  return (
    <div className="empty-layout">
      {token ? <Outlet/> : <Navigate to="/ar/login"/>}
    </div>
  );
};

export default EmptyLayout;
