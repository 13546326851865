import { useNavigate } from "react-router-dom";

export default function UnitBar({  unitsCount=0, searchPlaceholder="ابحث باسم الوحدة..." , searchInput, ButtonLabel="اضافة وحدة جديدة", ButtonLink, unitLabel="كل الوحدات" }) {

    const navigate = useNavigate();
    const handleClickButton = () => {
        navigate(ButtonLink);
    }
    return (
        <div className="rounded-[8px] bg-white h-[56px] flex flex-nowrap">
             <div className="leading-[50px] h-[50px] text-grey-900 font-[500] text-[20px] ps-[10px] pe-[10px] mt-[3px] whitespace-nowrap">
                {unitLabel}
                <span className="text-orange"> {`(${ unitsCount})`}</span>
             </div>
             <div className="leading-[50px] h-[50px] text-blue font-[500] text-[16px] pe-[15px] mt-[3px] border-e-[1px] border-e-grey-200 whitespace-nowrap cursor-pointer">
                <span className="inline-block w-[16px] h-[16px] !bg-center !bg-no-repeat align-middle me-[3px]" style={{background:"url('/assets/images/filter-search.svg')"}}></span>
                تصفية
                <span className="inline-block w-[16px] h-[16px] !bg-center !bg-no-repeat !bg-blue align-middle ms-[5px] me-[3px]" style={{maskImage:"url('/assets/images/arrow-down.svg')", maskPosition:"center"}}></span>
             </div>
             <div className="flex-auto relative ps-[25px] pe-[35px]">
                <input type="text" className="outline-none leading-[50px] h-[50px] mt-[3px] w-full text-[18px] text-grey-600 font-[400]" placeholder={searchPlaceholder} />
                <span className="absolute end-[35px] top-[16px] w-[24px] h-[24px] select-none cursor-pointer !bg-center !bg-no-repeat" style={{background:"url('/assets/images/search-icon.svg')"}}></span>
             </div>
             <div className="w-[258px] rounded-e-[8px] bg-blue cursor-pointer text-center text-white leading-[56px] text-[18px] font-[700]" onClick={()=>handleClickButton()}>
                {ButtonLabel}
             </div>
        </div>
    )

}