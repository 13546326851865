export default function NormalFormContainer({children, ...props}) {
    
    const heading = props.heading || 'من فضلك قم بملئ البيانات التالية.';
    
    return (
        <div className="mx-[-10px]">
        <p className="text-grey-900 text-[20px] font-[600] leading-[30px] mb-[10px]">{heading}</p>
        <div className="max-w-[928px] relative">
            <div className="h-[56px] leading-[56px] bg-grey-100 text-grey-900 font-[600] text-[16px] px-[16px] rounded-t-[8px]">{props?.label}</div>
            <div className="p-[24px] rounded-b-[8px] border-[1px] border-grey-200 border-t-0 bg-white">
                {children}
            </div>
        </div>
        </div>
    )
}