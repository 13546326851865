import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import apiClient from "../../components/utils/apiClient";
import MainContent from "../../containers/shared/MainContent";
import NormalFormContainer from "../../containers/shared/NormalFormContainer";
import InputField from "../../components/shared/InputField";
import SelectField from "../../components/shared/SelectField";
import ModalContainer from "../../containers/shared/ModalContainer";
import NormalButton from "../../components/shared/NormalButton";
import SingleImageUpload from "../../components/shared/SingleImageUpload";
import Toast from "../../components/shared/Toast";
import Loading from "../../components/shared/Loading";

const WalletPage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('المحفظة');
    const navigate = useNavigate();
    const pageTitle = {title: 'المحفظة', subTitle: 'يمكنك متابعة مصروفاتك وشحن رصيد المحفظة'};
    const [userData, setUserData] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [rechargeModal, setRechargeModal] = useState(false);
    const [rechargeAmount, setRechargeAmount] = useState('');
    const [isRechargeDisabled, setIsRechargeDisabled] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [showLoading, setShowLoading] = useState(false);
    const [toast, setToast] = useState({show: false,type: null, message:'' });
    const [pageSize, setPageSize] = useState(14);
    const [currentPage,setCurrentPage] = useState(1);
    const [pagesCount,setPagesCount] = useState(null);
    
    const getTransactions = async() => {
      try {
        const response = await apiClient.get(`/system-transactions/b-business/${userData?.business?._id}/wallet`);
        if(response?.data) {
          const transaction = response?.data?.transactions?.filter(t=>t?.type !== 'commission');
          setTransactions(transaction);
          setTotalTransactions(response?.data?.transactions?.filter(t=>t?.type !== 'commission')?.length);
          const pagesCount = response?.data?.transactions?.filter(t=>t?.type !== 'commission')?.length / pageSize;
          setPagesCount(Math.ceil(pagesCount));
        } else {

        }
      } catch(err) {}
    }

    useEffect(()=>{
      if(userData) {
        getTransactions();
      }
    },[userData])

    const handleGetUser = (user) => {
        setUserData(user);
    }

    const handleCloseModal = () => {
      setRechargeModal(false);
    }

    const handleClickRechargeWallet = () => {
      setRechargeModal(true);
      setRechargeAmount('');
      setIsRechargeDisabled(true);
    }

    useEffect(()=>{
      if(rechargeAmount !== '' && rechargeAmount > 0) {
        setIsRechargeDisabled(false);
      } else {
        setIsRechargeDisabled(true);
      }
    },[rechargeAmount])


    const handleRechargeAction = async() => {
        setIsRechargeDisabled(true);
        let callback;
        callback =  window?.location?.href;
        try {
        const response = await apiClient.post(`/b-business/recharge`,{amount:rechargeAmount,frontEndCallback:callback});
        if(response?.data) {
          window.location.href = response?.data?.formUrl;
          return false;
        } else {
          setIsRechargeDisabled(false);
        }
        } catch(err) {setIsRechargeDisabled(false);}
    }

    useEffect(()=>{
      if(searchParams.get("status") && searchParams.get("status") === 'paid') {
        setToast({show:true,type:'success',message:'لقد تم تنفيذ طلبك بنجاح'});
      }
      if(searchParams.get("status") && searchParams.get("status") !== 'paid') {
        setToast({show:true,type:'error',message:'حدث خطأ ما من فضلك حاول مرة اخري في وقت لاحق'});
      }
    },[])

   const handleClickChangePage = (page) => {
    setCurrentPage(page);
   }

  return (
    <>
        <div>
        <PageHeader pageTitle={pageTitle} userCallback={handleGetUser} />
        <PageMain>
            <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
            {userData && transactions && <MainContent>
                <div className="flex flex-nowrap gap-x-[20px] mt-[15px]">
                  <div className="w-[calc(100%-325px)]">
                    
                      { Array.isArray(transactions) && transactions?.length > 0 ?
                        <><table className="w-full" dir="rtl">
                          <thead>
                            <tr className="border-b-[2px] border-b-grey-50">
                              <th className="bg-white leading-[56px] rounded-ss-[8px] text-start px-[15px] text-[16px] font-[500px] w-[128px] border-l-[1px] border-l-grey-100">نوع المعاملة</th>
                              <th className="bg-white leading-[56px] text-start px-[15px] text-[16px] font-[500px] border-l-[1px] border-l-grey-100">المعاملة</th>
                              <th className="bg-white leading-[56px] text-start px-[15px] text-[16px] font-[500px] w-[128px] border-l-[1px] border-l-grey-100">المبلغ</th>
                              <th className="bg-white leading-[56px] rounded-se-[8px] text-start px-[15px] text-[16px] font-[500px] w-[128px]">التاريخ</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.slice((currentPage*pageSize)-(pageSize),(currentPage*pageSize)).map((transaction, index) => {
                              return (
                              <tr key={`transactions-${index}`}>
                                <td className={`${ index % 2 == 0 ? 'bg-white' : 'bg-grey-100'} leading-[40px] px-[15px] text-grey-900 font-[500] text-[14px] whitespace-nowrap`}>
                                  {transaction?.status === 'paid' && transaction?.type !== 'subscription' && transaction?.type !== 'payment' && <div className="text-success"><span className="w-[16px] h-[16px] !bg-center !bg-no-repeat inline-block align-middle" style={{background: 'url("/assets/images/received.svg")'}}></span> رصيد مضاف</div>}
                                  {transaction?.status === 'paid' && (transaction?.type === 'subscription' || transaction?.type === 'payment') && <div className="text-danger"><span className="w-[16px] h-[16px] !bg-center !bg-no-repeat inline-block align-middle" style={{background: 'url("/assets/images/send.svg")'}}></span> رصيد مسحوب</div>}
                                </td>
                                <td className={`${ index % 2 == 0 ? 'bg-white' : 'bg-grey-100'} leading-[40px] px-[15px] text-grey-900 font-[500] text-[14px]`}>{transaction?.memo}</td>
                                <td className={`${ index % 2 == 0 ? 'bg-white' : 'bg-grey-100'} leading-[40px] px-[15px] text-grey-900 font-[500] text-[14px] !text-right whitespace-nowrap`} dir="ltr">{transaction?.amount} <span>sr</span></td>
                                <td className={`${ index % 2 == 0 ? 'bg-white' : 'bg-grey-100'} leading-[40px] px-[15px] text-grey-900 font-[500] text-[14px] whitespace-nowrap`}>{new Date(transaction?.createdAt).toLocaleDateString("ar-eg", {year: 'numeric', month: 'long', day: 'numeric'})}</td>
                              </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        <div className="flex my-[24px]">
                          <div className="w-[50%] text-grey-600 font-[500] leading-[24px] text-[16px]">عرض {(currentPage*pageSize)-(pageSize-1)} ل {Math.min(currentPage*pageSize,transactions?.length)} من {transactions?.length} معاملة</div>
                          <div className="w-[50%] text-end"><div className="inline-block">
                            <ul className="ml-auto flex items-center">
                              <li className={`font-[500] text-[14px] w-[24px] h-[28px] leading-[28px] text-grey-300 bg-white cursor-pointer text-center me-[1px] rounded-s-[8px] ${currentPage === 1 && 'pointer-events-none'}`} onClick={()=>handleClickChangePage(currentPage-1)}><img src="/assets/images/arrow-left-blue.svg" className={`${currentPage === 1 && 'pointer-events-none opacity-50'} rotate-180 w-[20px] h-[20px] mx-[2px] my-[4px]`} alt="" /></li>
                              {[...Array(pagesCount).keys()].map((page,index)=><li key={`pageNumber${page}-${index}`} className={`me-[1px] font-[500] text-[14px] w-[24px] h-[28px] leading-[28px] text-grey-300 bg-white cursor-pointer text-center ${currentPage === page+1 && 'text-orange pointer-events-none'}`} onClick={()=>handleClickChangePage(page+1)}>{page+1}</li>)}
                              <li className={`font-[500] text-[14px] w-[24px] h-[28px] leading-[28px] text-grey-300 bg-white cursor-pointer text-center me-[1px] rounded-s-[8px] ${currentPage === pagesCount && 'pointer-events-none'}`} onClick={()=>handleClickChangePage(currentPage+1)}><img src="/assets/images/arrow-left-blue.svg" className={`${currentPage === pagesCount && 'pointer-events-none opacity-50'} w-[20px] h-[20px] mx-[2px] my-[4px]`} alt="" /></li>
                            </ul>
                            </div></div>
                        </div>
                        </> 
                      :
                        <div className="pt-[80px] text-center">
                          <div className="inline-block w-[188px] h-[188px] !bg-contain !bg-center" style={{background: "url('/assets/images/wallet-search.svg')"}}></div>
                          <div className="w-full text-center text-grey-900 text-[24px] font-[500] mb-[25px]">لم تقم باي معاملات علي محفظتك الي الان</div>
                        </div>
                      }

                  </div>
                  <div className="w-[325px]">
                    <div className="w-[324px] h-[186px] relative !bg-center !bg-no-repeat mb-[10px]" style={{background:"url('/assets/images/wallet-card.svg')"}}>
                        <div className="w-[65px] h-[24px] absolute top-[15px] start-[15px] !bg-contain !bg-no-repeat" style={{background:"url('/assets/images/logo-white.svg')"}}></div>
                        <div className="absolute bottom-[15px] end-[15px] text-white font-[500] text-[14px] leading-[21px]">رصيد المحفظة المتاح</div>
                        <div className="w-full leading-[186px] relative z-[1] text-white text-center font-[700] text-[38px]" dir="ltr">{userData?.business?.credits} <span className="font-[500] text-[18px]">sr</span></div>
                    </div>
                    <NormalButton label={'شحن المحفظة'} full color={'bg-grey-800'} onClick={()=>handleClickRechargeWallet()} />
                  </div>
                </div>
            </MainContent>}
        </PageMain>
        </div>
       { rechargeModal && <ModalContainer heading="شحن المحفظة" handleCloseModal={handleCloseModal}>
          <div className="flex flex-nowrap gap-x-[10px]">
            <div className="relative w-[calc(100%-103px)]">
              <span className="font-[500] text-[16px] text-grey-600 absolute end-[15px] bottom-[23px] z-[1] select-none">ريال سعودي</span>
              <InputField label={'القيمة المراد شحنها'} value={rechargeAmount} onChange={(e)=>{ if(e.target.value === '' || (e.target.value > 0 && /^[0-9\b]+$/.test(e.target.value))) { setRechargeAmount(e.target.value ) }}} />
            </div>
            <div className="w-[103px] pt-[24px]"><NormalButton label={'شحن'} isDisabled={isRechargeDisabled} onClick={()=>handleRechargeAction()} /></div>
          </div>
        </ModalContainer> }
        <Toast toast={toast} setToast={setToast} />
        <Loading showLoading={showLoading} />
    </>
  );
};

export default WalletPage;
