export default function StepsBar({steps=8, points=4, labels, current=1}) {
    if(!labels) {
        labels = [
            'إختيار الوحدات',
            'إختار الخدمات',
            'تحديد توقيت الخدمه',
            'مراجعة الطلب والدفع'
        ]
    }
    
    return (
        <div className="relative flex flex-nowrap content-between justify-center mt-[-15px] px-[25px]">
            <div className="">
                <div>
                    <div className={`w-[40px] h-[40px] leading-[40px] text-center mb-[12px] bg-grey6 rounded-full mx-auto border-[2px] border-[transparent] rotate-45 transition-all ${current === 1 && 'border-t-success border-r-success'} ${current > 1 && 'border-none bg-success text-white'}`}>
                        <span className="block text-tgrey text-[15px] font-[500] !rotate-[-45deg]">{current > 1 ? <span className="block w-[40px] h-[40px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/check.svg')", backgroundSize:'20px'}}></span> : 1}</span>
                    </div>
                    <span className="text-darkBlue text-[16px] font-[500] leading-[20px]">
                        {Array.isArray(labels) && labels?.length > 0 && labels[0]}
                    </span>
                </div>
            </div>
            <div className="grow">
                <div className="relative flex flex-nowrap content-between justify-center px-[25px]">
                    {new Array(points-1).fill('').map((_point,index)=>{
                        return (
                        <div key={`points-${index}`} className="relative flex flex-nowrap" style={{width:`${100/Number(points-1)}%`}}>
                            <div className="grow px-[25px] relative">
                                <span className={`absolute block w-[calc(100%-50px)] h-[2px] bg-grey-500 top-[50%] mt-[-1px]`}><span className={`block h-[2px] bg-success transition-all w-0 ${current > (Number(index+1)*2)-1 ? '!w-[100%]' : current > (Number(index+1)*2)-2 ? '!w-[50%]' :  '' }`}></span></span>
                                <span className={`absolute block w-[10px] h-[10px] rounded-full bg-grey-500 top-[50%] mt-[-5px] transition-all ${current > (Number(index+1)*2)-2 && '!bg-success'}`}></span>
                                <span className={`absolute block w-[10px] h-[10px] rounded-full bg-grey-500 top-[50%] mt-[-5px] end-[25px] transition-all ${current > (Number(index+1)*2)-1 && '!bg-success'}`}></span>
                            </div>
                            <div>
                                <div className={`w-[40px] h-[40px] leading-[40px] text-center mb-[12px] bg-grey6 rounded-full mx-auto border-[2px] border-[transparent] rotate-45 transition-all ${current > (Number(index+2)*2)-2 && 'border-t-success border-r-success'} ${current > (Number(index+2)*2)-1 && 'border-none bg-success text-white'}`}>
                                    <span className="block text-tgrey text-[15px] font-[500] !rotate-[-45deg]">{current > (Number(index+2)*2)-1 ? <span className="block w-[40px] h-[40px] !bg-center !bg-no-repeat" style={{background:"url('/assets/images/check.svg')", backgroundSize:'20px'}}></span> : index+2}</span>
                                </div>
                                <span className="text-darkBlue text-[16px] font-[500] leading-[20px]">
                                    {Array.isArray(labels) && labels?.length > 0 && labels[Number(index+1)]}
                                </span>
                            </div>
                        </div>
                    )
                    })}
                </div>
            </div>
        </div>
    )
} 