import { useEffect, useMemo, useRef, useState } from "react";
import apiClient from "../utils/apiClient";
import useDebounce from "../helpers/useDebounce";

import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { Popup, Marker, useMap } from "react-leaflet";
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';

export default function MapAutocomplete({setAddressDetails,addressDetails,errorMessage,error}){




    const [hasData, setHasData] = useState(addressDetails ? true : false);

    const [searchVal, setSearchVal] = useState(addressDetails ? addressDetails.addressLine : '');
    const [debounceVal, setDebounceVal] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [finalLocation, setFinalLocation] = useState(null);
    const [searchText,setSearchText] = useState(null);
    const [center, setCenter] = useState( addressDetails 
        ? { lat: addressDetails?.location?.latitude, lng: addressDetails?.location?.longitude }
        : { lat: 24.638916, lng: 46.7160104 }
    );

    const [geoLocation, setGeoLocation] = useState(null);



  


    const debounceValue = useDebounce(searchVal, 1000);

    useEffect(() => {
        if( !hasData && searchVal && (JSON.stringify(searchVal) !== JSON.stringify(searchText))) {
            getMap(searchVal);
        }
    }, [debounceValue]);

    const handleChange = (e) => {
        setHasData(false);
        setSearchVal(e.target.value);
    };

    const getMap = async (address) => {
        try {
            const response = await apiClient?.get(`https://nominatim.openstreetmap.org/search.php?q=${address}&polygon_geojson=1&accept-language=ar&countrycodes=sa&format=jsonv2`)
            if(response?.data) {
                setSearchResults(response?.data);
            }
        } catch (err) {

        }
    }

    const [position, setPosition] = useState(center);

    function DraggableMarker() {
        const [draggable, setDraggable] = useState(false)
        const markerRef = useRef(null)
        const eventHandlers = useMemo(
            () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                }
            },
            }),
            [],
        )

        const newicon = new L.icon({
            iconUrl: '/assets/images/marker.svg',
            iconSize: [18, 24]
          });


        return (
            <Marker
                icon={newicon}
                draggable={true}
                eventHandlers={eventHandlers}
                position={position}
                ref={markerRef}>
            </Marker>
        )
    }

    

    useEffect(()=>{
        setFinalLocation({lat:position?.lat,lng:position?.lng});
        if(center && position && (JSON.stringify(center) !== JSON.stringify(position))) {
            getDisplayName(position?.lat,position?.lng);
        }
    },[position])

    const handleClickAddress = (lat,lng,name,id,type) => {
        getAddressDetails(id,type,name,lat,lng);
        setSearchResults([]);
        setCenter({lat: lat,lng: lng});
        setFinalLocation({lat: lat,lng: lng});
        setPosition({lat: lat,lng: lng});
        setSearchText(name);
    }

    const RecenterAutomatically = ({lat,lng}) => {
        const map = useMap();
         useEffect(() => {
                map.setView([lat, lng]);
         }, [lat, lng]);
         return null;
       }


    const getDisplayName = async (lat,lng) => {
        try {
            const response = await apiClient?.get(`https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${lng}&zoom=13&format=jsonv2`)
            if(response?.data) {
                setSearchText(response?.data?.display_name);
                getAddressDetails(response?.data?.osm_id,response?.data?.osm_type,response?.data?.display_name,lat,lng);
            }
        } catch (err) {

        }
    }

    useEffect(()=>{
        if(searchText) {
            setSearchVal(searchText);
        }
    },[searchText])


    const getAddressDetails = async (id,type,display_name,lat,lng) => {
        try {
            const response = await apiClient?.get(`https://nominatim.openstreetmap.org/details.php?osmtype=${type.substring(0,1).toUpperCase()}&osmid=${id}&addressdetails=1&hierarchy=0&group_hierarchy=1&polygon_geojson=1&format=json`)
            if(response?.data) {
                const finalAddressData = {
                    "addressLine": display_name,
                    "city": response?.data?.address[response?.data?.address?.findIndex(i=>i.type==='city')]?.localname,
                    "district": {
                      "en": response?.data?.names['name:en'] || response?.data?.names['name'],
                      "ar": response?.data?.names['name:ar'] || response?.data?.names['name']
                    },
                    "location": {
                      "latitude": parseFloat(lat),
                      "longitude": parseFloat(lng)
                    }
                  }
                  if(finalAddressData?.city) {
                      setAddressDetails(finalAddressData);
                  } else {
                    getReverseCity(response?.data?.centroid?.coordinates[1], response?.data?.centroid?.coordinates[0],finalAddressData);
                  }
                  setCenter({lat:lat, lng: lng})
            }
        } catch(err) {

        }
    }


    const getReverseCity = async (lat,lng,finalForm) => {
        try {
            const response = await apiClient?.get(`https://nominatim.openstreetmap.org/reverse.php?lat=${lat}&lon=${lng}&zoom=10&format=jsonv2`)
            if(response?.data) {
                const finalAddressData = finalForm;
                finalAddressData.city = response?.data?.name;
                setAddressDetails(finalAddressData);
            }
        } catch (err) {

        }
    }


    const getLocation = () => {
        if (!navigator.geolocation) {
             console.log('Geolocation is not supported by your browser');
        } else {
             navigator.geolocation.getCurrentPosition((position) => {
                setPosition({lat:position.coords.latitude,lng:position.coords.longitude});
             }, () => {
             });
        }
   }


    return (
        <>
        <div className={`w-full h-[336px] overflow-hidden rounded-[8px] relative border-[1px] border-[transparent] ${error ? '!border-danger' : ''}`}>
            <div className="absolute z-[16] bottom-[25px] rounded-[3px] border border-grey-300 bg-white left-[18px] w-[30px] h-[30px] cursor-pointer p-[5px]" onClick={getLocation}><img src="/assets/images/geolocation.svg" alt="" className="w-[20px] h-[20px]" /></div>
            <input type="text" value={searchVal} onChange={handleChange} className="absolute z-[5] w-[calc(100%-32px)] top-[16px] start-[16px] h-[48px] border-[1px] border-grey-300 rounded-[8px] outline-none text-grey-900 text-[16px] font-[500] ps-[15px]" placeholder="اكتب العنوان التفصيلي للوحدة" />
            {Array.isArray(searchResults) && searchResults?.length > 0 && <div className="absolute w-[calc(100%-32px)] top-[55px] bg-white rounded-b-[8px] z-[5] start-[16px] border-[1px] border-grey-300 border-t-[0]">
                {searchResults?.map(result=> <div key={result?.display_name} className="border-b-[1px] border-b-grey-300 leading-[36px] px-[16px] cursor-pointer" onClick={()=>handleClickAddress(result?.lat,result?.lon,result?.display_name,result?.osm_id,result?.osm_type)}>{result?.display_name}</div>)}
            </div>}
            <div className={`z-[1] h-[336px] w-full relative`}>
                <MapContainer style={{height:"336px"}} center={center} zoom={13}>
                    <ReactLeafletGoogleLayer apiKey='AIzaSyAfGXsmK9ljk5dnMnTsVRkvJL0mORN1LBY' type={'roadmap'} />
                    
                    <DraggableMarker />
                    <RecenterAutomatically lat={center?.lat} lng={center?.lng} />
                </MapContainer>
            </div>
        </div>
        {errorMessage && <p className="text-danger text-[14px] font-[400] leading-[20px] pe-[5px] pt-[3px] mb-[0] block"><img src="/assets/images/error.svg" alt="" className="inline w-[16px] h-[16px] align-middle me-[3px]" />{errorMessage}</p>}
        </>
    )
}