import React from "react";

const NotFoundPage = () => {
  return (
    <div>
      <h3></h3>
    </div>
  );
};

export default NotFoundPage;
