import { useState } from "react";
import { useTranslation } from "react-i18next";


export default function TextArea({label,value,name,placeholder,onChange,disabled,error,errorMessage,translateError=false,isRequird=false,onkeydown, autoComplete='off',role}){

    const {t} = useTranslation();
    

    return (
        <div className="min-h-[40px] w-[100%] mb-[10px] relative">
            { label && <label htmlFor={label} className="text-grey-900 text-[14px] font-[600] leading-[21px] mb-[3px] block">{label}{isRequird && <span className="text-danger">*</span>}</label> } 
           <textarea autoComplete={autoComplete} role={role} id={label} name={name} placeholder={placeholder} onChange={onChange} onKeyDown={onkeydown} disabled={disabled} 
           className={`text-grey-600 transition-[border] border-grey-300 ease-in-out duration-300 focus:border-lightBlue outline-none p-[12px] h-[153px] text-[16px] w-[100%] bg-white rounded-[8px] border justify-start items-center inline-flex leading-normal ${ error ? '!border-danger' : '' } ${ disabled ? '!bg-disabled !border-disabled' : '' }`} defaultValue={value}></textarea>
            {errorMessage && <p className="text-danger text-[14px] font-[400] leading-[20px] pe-[5px] pt-[3px] mb-[0] block"><img src="/assets/images/error.svg" alt="" className="inline w-[16px] h-[16px] align-middle me-[3px]" />{translateError ? t(errorMessage) : errorMessage}</p>}
        </div>
    )
}