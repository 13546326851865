import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import apiClient from "../../components/utils/apiClient";
import MainContent from "../../containers/shared/MainContent";
import NormalFormContainer from "../../containers/shared/NormalFormContainer";
import InputField from "../../components/shared/InputField";
import SelectField from "../../components/shared/SelectField";
import ModalContainer from "../../containers/shared/ModalContainer";
import NormalButton from "../../components/shared/NormalButton";
import MultiSelect from "../../components/shared/MultiSelect";
import FilterSelectedValues from "../../components/helpers/filterSelectedValues";
import getUniqueListByKey from "../../components/helpers/GetUniqueListByKey";

const UsersAddPage = () => {
    const {t,i18n} = useTranslation();
    useDocumentTitle('اضف مستخدم جديد');
    const navigate = useNavigate();
    const pageTitle = {title: 'اضف مستخدم جديد', subTitle: 'يمكنك اضافة مستخدمين النظام الجدد.'};
    const [addUserForm,setAddUserForm] = useState({name: '', mobile: '', email:'', role: '', propertyIds:[], companyName: 'defult', unitIds:[] });
    const [errors, setErrors] = useState(null);
    const [roleInfoModal, setRoleInfoModal] = useState(false);
    const [isAddUserFormDisabled, setIsAddUserFormDisabled] = useState(true);
    const [properties, setProperties] = useState(null);
    const [propertiesObjects, setPropertiesObjects] = useState(null);
    const [propertiesIds, setPropertiesIds] = useState([]);
    const [units, setUnits] = useState(null);
    const [getUnitsTrigger, setGetUnitsTrigger] = useState(0);
    const [selectedUnitsOptions, setSelectedUnitsOptions] = useState([])



    const getProperties = async () => {
        try {
          const response = await apiClient.get(`/b-properties`);
          if(response?.data) {
            setPropertiesObjects(response?.data?.properties);
            setProperties(response?.data?.properties?.map((property) => ({value: property?._id, label: property?.name}) ));
            setPropertiesIds(response?.data?.properties?.map(property =>property?._id));
          } else {
  
          }
        } catch(err) {}
      }

      const getUnits = (propertiesIds) => {
        const getUnits = [];
        propertiesObjects?.map((property) => {
            property?.units?.map((unit) => getUnits.push(unit));
        });
        setUnits(getUnits)
      }

      useEffect(()=>{
        if(Array.isArray(units) && units?.length > 0) {
            const newUnits = getUniqueListByKey(units,'_id');
            if(Array.isArray(newUnits) && newUnits?.length > 0) {
                setUnits(newUnits);
            }
        }
      },[getUnitsTrigger])
  
      useEffect(()=>{
        getProperties();
      },[]);

      useEffect(()=>{
        if(Array.isArray(propertiesIds) && propertiesIds?.length) {
            getUnits(propertiesIds);
        }
      },[propertiesIds]);

  
    useEffect(()=>{
      if(addUserForm && addUserForm?.name && addUserForm.email && addUserForm.mobile && addUserForm.role)  {
        setIsAddUserFormDisabled(false);
      } else {
        setIsAddUserFormDisabled(true);
      }
    },[addUserForm]);
  
    const hideError = (err) => {
      if(errors) {
        let errorsList = errors;
        delete errorsList[err];
        setErrors(errorsList);
      }
    }

    const handleClickInfoIcon = () => {
        setRoleInfoModal(true);
    }
    const handleCloseModal = () => {
        setRoleInfoModal(false);
    }

    const handleSubmitAddUser = () => {
        setErrors(null);
        let errorsList = {};
        if(addUserForm?.name?.length < 2) {
            errorsList.name = 'validation.nameLength';
            }
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(addUserForm?.email)) {
            errorsList.email = 'validation.invalidEmail';
            }
            if(!/^(5)([0-9]{8})$/.test(addUserForm?.mobile?.replace('+966',''))) {
            errorsList.mobile = 'validation.invalidmobile';
            }
            if(Object.keys(errorsList).length > 0) {
            setErrors(errorsList);
        } else {
            handleClickSubmitAddUserAction();
        }
    }

    const handleClickSubmitAddUserAction = async () => {
        setIsAddUserFormDisabled(true);
        const finalAddUserForm = addUserForm;
        try {
        const response = await apiClient.post(`/b-users`,Object.fromEntries(Object.entries(finalAddUserForm).filter(([_, v]) => v !== '')));
        if(response?.data) {
            navigate(`/ar/users`);
        } else {
            setIsAddUserFormDisabled(false);
        }
        } catch(err) {
            setIsAddUserFormDisabled(false);
            if(err?.response?.data?.errors) {
                let errorsList = {};
                err?.response?.data?.errors?.map(err=>{
                    errorsList[err.field] = err.message; 
                });
                setErrors(errorsList);
            }
        }
    }

    const handleChangePropertySelect = (options) => {
        const IdsList = FilterSelectedValues(options);
        const newUnitsOptions = [];
        const newUnitsIds = [];
        IdsList?.map((id) => {
            units?.map((unit) => {if (unit?.propertyId === id) { newUnitsOptions.push({value: unit?._id, label: unit?.name}); newUnitsIds.push(unit?._id) }});
        });
        setSelectedUnitsOptions(newUnitsOptions);
        const newSelectedUnitsValues = addUserForm?.unitIds?.filter(unitId => newUnitsIds.includes(unitId));
        setAddUserForm({ ...addUserForm, unitIds: newSelectedUnitsValues, propertyIds: IdsList });
        hideError('propertyIds');
    }

  return (
    <>
         <div>
        <PageHeader pageTitle={pageTitle} />
        <PageMain>
            <MainContent>

                <NormalFormContainer heading="من فضلك قم بملئ البيانات التالية لإضافة مستخدم جديد." label="اضف مستخدم">
                    
                    <div className="mb-[15px]">
                        <InputField label={t("forms.nameLabel")} placeholder={'اكتب اسم المستخدم هنا'} value={addUserForm?.name} error={errors?.name} errorMessage={errors?.name} translateError={true} onChange={(e)=>{setAddUserForm({ ...addUserForm, name: e.target.value }); hideError('name')}} />
                    </div>

                    <div className="mb-[15px]">
                        <InputField phone={true} label={t("forms.mobileLabel")} value={addUserForm?.mobile?.replace('+966','')} error={errors?.mobile} errorMessage={errors?.mobile} translateError={true} onChange={(e)=>{ if(e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) { setAddUserForm({ ...addUserForm, mobile: '+966'+e.target.value }) }; hideError('mobile')}} />
                    </div>

                    <div className="mb-[15px]">
                        <InputField label={'الايميل'} placeholder={'اكتب ايميلك'} value={addUserForm?.email} error={errors?.email} errorMessage={errors?.email} translateError={true} onChange={(e)=>{setAddUserForm({ ...addUserForm, email: e.target.value }); hideError('email')}} />
                    </div>

                    <div className="mb-[15px]">
                        <SelectField infoIcon={true} handleClickInfoIcon={handleClickInfoIcon} label={'إختار دور المستخدم'} placeholder={'اختر دور المستخدم والذي سيترتب عليه صلاحياته في استخدام النظام'} options={[{label:'المدير',value:'ADMINISTRATOR'},{label:'الموظف',value:'EMPLOYEE'}]} value={addUserForm?.role} onChange={(e)=>{setAddUserForm({ ...addUserForm, role: e.target.value }); hideError('role')}} />
                    </div>

                    <div className="mb-[15px]">
                        <MultiSelect isMulti label={'إختار المنشأة المعينة للمستخدم '} placeholder={'إسم المنشأة'} options={properties} error={errors?.propertyIds} errorMessage={errors?.propertyIds} value={addUserForm?.propertyIds} onChange={(options)=>{handleChangePropertySelect(options)}} />
                    </div>

                    { Array.isArray(addUserForm?.propertyIds) && addUserForm?.propertyIds?.length > 0 && <div className="mb-[15px]">
                        <MultiSelect isMulti label={'إختار الوحدة المعينة للمستخدم '} placeholder={'إسم الوحدة'} options={selectedUnitsOptions} error={errors?.unitIds} errorMessage={errors?.unitIds} value={addUserForm?.unitIds} onChange={(options)=>{setAddUserForm({ ...addUserForm, unitIds: FilterSelectedValues(options)});hideError('unitIds');}} />
                    </div>}

                    <div className="text-end">
                        <NormalButton label={'إضافة المستخدم'} onClick={handleSubmitAddUser} isDisabled={isAddUserFormDisabled} />
                    </div>

                    {properties?.length === 0 || units?.length === 0 && <div className="bg-white/50 absolute w-full h-full top-0 left-0 right-0 bottom-0">
                        <div className="bg-black/50 text-white p-[25px] rounded-[8px] text-center font-[600] text-[21px] max-w-[300px] mx-auto mt-[200px]">من فضلك يجب اضافة منشأت ووحدات اولا لتتمكن من اضافة مستخدم جديد.</div>
                    </div>}

                </NormalFormContainer>
            </MainContent>
        </PageMain>
        </div>
        { roleInfoModal && <ModalContainer heading="ادوار المستخدمين" handleCloseModal={handleCloseModal}>
            <p className="bg-grey-100 rounded-[8px] p-[12px] mb-[20px]">
                <span className="inline-block bg-darkBlue text-white rounded-[23px] px-[8px] h-[32px] leading-[32px] mb-[10px] font-[600] text-[16px]">مالك حساب</span>
                <span className="block text-[12px] text-grey-900 font-[400] leading-[18px]">نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور </span>
            </p>
            <p className="bg-grey-100 rounded-[8px] p-[12px] mb-[20px]">
                <span className="inline-block bg-blue text-white rounded-[23px] px-[8px] h-[32px] leading-[32px] mb-[10px] font-[600] text-[16px]">المدير</span>
                <span className="block text-[12px] text-grey-900 font-[400] leading-[18px]">نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور </span>
            </p>
            <p className="bg-grey-100 rounded-[8px] p-[12px] mb-[20px]">
                <span className="inline-block bg-orange text-white rounded-[23px] px-[8px] h-[32px] leading-[32px] mb-[10px] font-[600] text-[16px]">الموظف</span>
                <span className="block text-[12px] text-grey-900 font-[400] leading-[18px]">نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور نصاً ما باللغة العربية يمكن استبداله في وقت لاحق ينص علي صلاحيات الدور المذكور </span>
            </p>
            <div className="text-end">
                <NormalButton label={'اغلاق'} onClick={handleCloseModal} />
            </div>
        </ModalContainer> }
    </>
  );
};

export default UsersAddPage;
