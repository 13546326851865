import React, { useEffect, useState } from "react";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import { useTranslation } from "react-i18next";
import InputField from "../../components/shared/InputField";
import Button from "../../components/shared/Button";
import { Link, useNavigate } from "react-router-dom";
import SelectField from "../../components/shared/SelectField";
import apiClient from "../../components/utils/apiClient";

const RegisterPage = () => {

  const {i18n,t} = useTranslation();
  useDocumentTitle('pageTitles.register');
  const navigate = useNavigate();
  const [isFormSubmitDisabled,setIsFormSubmitDisabled] = useState(true);
  const [signUpForm,setSignUpForm] = useState({name: '', email:'', mobile: '', companyName: '', numOfProperties: '' });
  const [errors, setErrors] = useState(null);

  useEffect(()=>{
    if(signUpForm && signUpForm?.name && signUpForm.email && signUpForm.mobile && signUpForm.companyName && signUpForm.numOfProperties) {
      setIsFormSubmitDisabled(false);
    } else {
      setIsFormSubmitDisabled(true);
    }
  },[signUpForm]);

  const hideError = (err) => {
    if(errors) {
      let errorsList = errors;
      delete errorsList[err];
      setErrors(errorsList);
    }
  }


  const handleClickSignUpButton = () => {
    setErrors(null);
    let errorsList = {};
    if(signUpForm?.name?.length < 2) {
      errorsList.name = 'validation.nameLength';
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signUpForm?.email)) {
      errorsList.email = 'validation.invalidEmail';
    }
    if(!/^(5)([0-9]{8})$/.test(signUpForm?.mobile?.replace('+966',''))) {
      errorsList.mobile = 'validation.invalidmobile';
    }
    if(signUpForm?.companyName?.length < 2) {
      errorsList.companyName = 'validation.companyNameLength';
    }
    if(Object.keys(errorsList).length > 0) {
      setErrors(errorsList);
    } else {
      handleClickSignUpButtonAction();
    }
  }


  const handleClickSignUpButtonAction = async () => {
    setIsFormSubmitDisabled(true);
    const finalSignUpForm = signUpForm;
    finalSignUpForm.numOfProperties =  parseInt(finalSignUpForm?.numOfProperties);
    try {
      const response = await apiClient.post(`/b-owners/register`,Object.fromEntries(Object.entries(finalSignUpForm).filter(([_, v]) => v !== '')));
      if(response?.data) {
        navigate(`/${i18n.language}/register-success`);
      } else {
        setIsFormSubmitDisabled(false);
      }
    } catch(err) {
      setIsFormSubmitDisabled(false);
      if(err?.response?.data?.errors) {
        let errorsList = {};
        err?.response?.data?.errors?.map(err=>{
            errorsList[err.field] = err.message; 
        });
        setErrors(errorsList);
    }
    }
  }

  return (
    <div className="w-full">
      <h3 className="text-grey-900 font-[600] text-[32px] leading-[48px]">{t('registerPage.welcome')}</h3>
      <p className="text-grey-600 font-[500] text-[18px] leading-[27px] mt-[15px] mb-[40px]">{t('registerPage.subHead')}</p>
      <p className="text-grey-900 font-[500] text-[18px] leading-[27px]">{t('registerPage.formHead')}</p>
      <div className="w-full lg:max-w-[450px] pt-[40px] pb-[35px] lg:pb-0">
        <div className="mb-[20px]">
            <InputField onkeydown={(e)=>e.key === 'Enter' && (!isFormSubmitDisabled) && handleClickSignUpButton() } label={t("forms.nameLabel")} placeholder={t("forms.namePlaceholder")} value={signUpForm?.name} error={errors?.name} errorMessage={errors?.name} translateError={true} onChange={(e)=>{setSignUpForm({ ...signUpForm, name: e.target.value }); hideError('name')}} />
        </div>
        <div className="mb-[20px]">
            <InputField onkeydown={(e)=>e.key === 'Enter' && (!isFormSubmitDisabled) && handleClickSignUpButton() } label={t("forms.emailLabel")} placeholder={t("forms.emailPlaceholder")} value={signUpForm?.email} error={errors?.email} errorMessage={errors?.email} translateError={true} onChange={(e)=>{setSignUpForm({ ...signUpForm, email: e.target.value }); hideError('email')}} />
        </div>
        <div className="mb-[20px]">
            <InputField onkeydown={(e)=>e.key === 'Enter' && (!isFormSubmitDisabled) && handleClickSignUpButton() } phone={true} label={t("forms.mobileLabel")} value={signUpForm?.mobile?.replace('+966','')} error={errors?.mobile} errorMessage={errors?.mobile} translateError={true} onChange={(e)=>{ if(e.target.value === '' || /^[0-9\b]+$/.test(e.target.value)) { setSignUpForm({ ...signUpForm, mobile: '+966'+e.target.value }) }; hideError('mobile')}} />
        </div>
        <div className="mb-[35px]">
            <InputField onkeydown={(e)=>e.key === 'Enter' && (!isFormSubmitDisabled) && handleClickSignUpButton() } label={t("forms.companyNameLabel")} placeholder={t("forms.companyNamePlaceholder")} type={'companyName'} value={signUpForm?.companyName} error={errors?.companyName} errorMessage={errors?.companyName} translateError={true} onChange={(e)=>{setSignUpForm({ ...signUpForm, companyName: e.target.value }); hideError('companyName')}} />
        </div>
        <div className="mb-[20px]">
            <SelectField label={t('forms.numOfPropertiesRangeLabel')} placeholder={t('forms.numOfPropertiesRangePlaceholder')} options={[{label:'1',value:1},{label:'2',value:2},{label:'3',value:3}]} value={signUpForm?.numOfProperties} onChange={(e)=>{setSignUpForm({ ...signUpForm, numOfProperties: e.target.value }); hideError('numOfProperties')}} />
        </div>
        <div className="mb-[35px]">
            <Button type="button" text={t('forms.requestDemo')} disabled={isFormSubmitDisabled} onClick={()=>handleClickSignUpButton()} />
        </div>
      </div>
      <p className="text-grey-600 text-[18px] font-[500] leading-[27px] absolute bottom-[15px] end-[24px] start-[24px]">{t('registerPage.signinText')} <Link className="text-blue font-[700]" to={`/${i18n.language}/login`}>{t('registerPage.signin')}</Link>.</p>
    </div>
  );
};

export default RegisterPage;
