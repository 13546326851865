export default function ModalContainer({children, ...props}) {

    const hideX = props?.hideX || false;
    const maxWidth = props?.maxWidth || '775px';

    

    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full bg-black/60 pt-[150px] text-center overflow-y-auto z-[100]">
            <div className="relative w-full inline-block bg-white p-[24px] rounded-[8px] text-start overflow-hidden" style={{maxWidth:`${maxWidth}`}}>
                <h2 className="text-[20px] text-grey-900 font-[600] leading-[30px] mb-[20px]">{props?.heading}</h2>
                {!hideX && <div className="w-[24px] h-[24px] absolute top-[26px] end-[24px] !bg-center !bg-no-repeat cursor-pointer select-none" style={{background:"url('/assets/images/close.svg')"}} onClick={()=>props?.handleCloseModal()}></div>}
                <div>
                    {children}
                </div>
            </div>
        </div>
    )
}