import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import apiClient from "../../components/utils/apiClient";
import MainContent from "../../containers/shared/MainContent";
import PropertyBar from "../../components/property/PropertyBar";
import PropertyCard from "../../components/property/PropertyCard";

const PropertyHomePage = () => {
    const {t,i18n} = useTranslation();
    const navigate = useNavigate();
    useDocumentTitle('الوحدات');
    const pageTitle = {title: 'الوحدات', subTitle: ''};
    const [properties, setProperties] = useState(null);
    const [unitsCount, setUnitsCount] = useState(null);

    const getProperties = async () => {
      try {
        const response = await apiClient.get(`/b-properties`);
        if(response?.data) {
          setProperties(response?.data?.properties?.filter(property=> !property?.deletedAt));
        } else {

        }
      } catch(err) {}
    }


    useEffect(()=>{
      getProperties();
    },[]);

    useEffect(()=>{
      if(properties && Array.isArray(properties) && properties?.length > 0) {
        let unitsCount = 0;
        properties?.map(property=> unitsCount = unitsCount + parseInt(property?.numberOfUnits));
        setUnitsCount(unitsCount);
      }
    },[properties]);

    const handleClickPropertyCard = (id) => {
      navigate(`/ar/property/${id}`);
    }
    
  return (
    <div>
      <PageHeader pageTitle={pageTitle} isColored/>
      <PageMain>
        <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
        <MainContent>
          <PropertyBar propertiesCount={unitsCount || 0} />
          {properties !== null && Array.isArray(properties) && properties?.length < 1 && 
            <div className="pt-[80px] text-center">
                <div className="inline-block w-[188px] h-[188px] !bg-contain !bg-center" style={{background: "url('/assets/images/main-property-icon.svg')"}}></div>
                <div className="w-full text-center text-grey-900 text-[24px] font-[500] mb-[25px]">لم تقم بإضافة اي منشآت حتي الأن</div>
                <Link className="inline-block min-w-[320px] h-[48px] leading-[48px] rounded-[8px] text-white bg-grey-800 text-[18px] font-[600]" to="/ar/property/add">إنشاء منشأة جديدة</Link>
            </div> }
            {properties !== null && Array.isArray(properties) && properties?.length > 0 && 
            <div className="pt-[25px] flex  gap-[32px] flex-wrap">
              {properties?.map(((property,index)=>{
                return <PropertyCard key={`property-${index}`} image={property?.files?.length > 0 && property?.files[0]} name={property?.name} description={property?.description} units={property?.numberOfUnits} onClick={()=>handleClickPropertyCard(property?._id)} />
              }))}
            </div> }
        </MainContent>
      </PageMain>
    </div>
  );
};

export default PropertyHomePage;
