import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function SelectField({label,type='text',options=[],value,name,placeholder,onChange,disabled,error,errorMessage,translateError=false,isRequird=false,onkeydown, autoComplete='off',role,infoIcon=false, handleClickInfoIcon}){

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const {t,i18n} = useTranslation();

    return (
        <div className="min-h-[40px] w-[100%] mb-[10px] relative">
            { label && <label htmlFor={label} className="text-grey-900 text-[14px] font-[600] leading-[21px] mb-[3px] block">{label}{isRequird && <span className="text-danger">*</span>}</label> } 
            { infoIcon && <span className="cursor-pointer w-[24px] h-[24px] block !bg-center !bg-no-repeat absolute top-[-5px] end-0" style={{background:"url('/assets/images/info-icon.svg')"}} onClick={()=>handleClickInfoIcon()}></span>}
            <select
                value={value}
                onChange={onChange}
                style={{background:`url('/assets/images/select-arrow.svg')`}}
                className={`appearance-none !bg-no-repeat text-grey-600 ltr:!bg-[calc(100%-15px)_50%] rtl:!bg-[15px_50%] transition-[border] border-grey-300 ease-in-out duration-300 focus:border-lightBlue outline-none px-[12px] h-[48px] text-[16px] w-[100%] bg-white rounded-[8px] border justify-start items-center inline-flex leading-[48px] ${ error ? '!border-danger' : '' } ${ disabled ? '!bg-disabled !border-disabled' : '' }`}
            >
                {placeholder && <option value="" defaultValue={!value} disabled={value}>{placeholder}</option>}
                {Array.isArray(options) && options?.length > 0 && options?.map(option=>{
                  return(<option key={option.label+'-'+option.value} value={option.value}>{option.label}</option>)  
                })}
            </select>
            {errorMessage && <p className="text-danger text-[14px] font-[400] leading-[20px] pe-[5px] pt-[3px] mb-[0] block"><img src="/assets/images/error.svg" alt="" className="inline w-[16px] h-[16px] align-middle me-[3px]" />{translateError ? t(errorMessage) : errorMessage}</p>}
        </div>
    )
}