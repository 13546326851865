import { useEffect, useState } from "react";
import { useNavState } from "../../layouts/appLayout";

export default function Toast({toast,setToast}){

    let icon;
    let color;

    switch(toast?.type){
        default:
            icon = 'shield-tick.svg';
            color = '!bg-success';
            break;
        case 'success':
            icon = 'shield-tick.svg';
            color = '!bg-success';
            break;
        case 'error':
            icon = 'shield-cross.svg';
            color = '!bg-danger';
            break;
    }

    const navState = useNavState();

    useEffect(()=>{
        setTimeout(()=>{
            setToast({...toast, show: false});
        },[2000])
    },[])

    useEffect(()=>{
        if(toast?.show === true) {
            setTimeout(()=>{
                setToast({...toast, show: false});
            },[2000])
        }
    },[toast])

    return (
        toast?.show && <div className={`fixed ${navState?.navState ? '!w-[calc(100vw-229px)]' : '!w-[calc(100vw-111px)]'} top-0 end-0 transition-all select-none z-[100]`}>
            <div className={`absolute top-[97px] left-[50%] translate-x-[-50%] px-6 py-3 rounded-[10px] justify-center items-center gap-[8px] inline-flex cursor-pointer select-none ${color}`} onClick={()=>setToast({...toast, show: false})}>
                <div className="w-[32px] h-[32px] !bg-center !bg-no-repeat" style={{background:`url('/assets/images/${icon}')`}}></div>
                <div className="text-white text-xl font-medium">{toast?.message}</div>
            </div>
        </div>
    )
}