import { CITIES } from "../utils/constants";

const CityConverter = (city,from='en') => {
    if(!city) return;
    if(from === 'en') {
        const result = Object.keys(CITIES)?.filter(c=> CITIES[c].en === city);
        if(result?.length >0) {
            return CITIES[result[0]]?.ar;
        } else {
            return 'notFound';
        }
    } else {
        const result = Object.keys(CITIES)?.filter(c=> CITIES[c].ar === city.replace('محافظة ',''));
        if(result?.length >0) {
            return CITIES[result[0]]?.en;
        } else {
            return 'notFound';
        }
    }
}

export default CityConverter;