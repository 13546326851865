import { Link, useLocation, useNavigate } from "react-router-dom";

export default function MainNavItem({navStatus,icon,label,link,grey300=false,disableArrow=false,type='normal'}) {


    const navigate = useNavigate();

    const arrow = grey300 ? 'arrow-right-300.svg':'arrow-right.svg';

    const location = useLocation();
    const { hash, pathname, search } = location;
    let matchRoute;

    if((link === '/' || link === '/ar' || link === '/ar/' || link.includes('dashboard')) && (pathname === '/' || pathname === '/ar' || pathname === '/ar/' || pathname.includes('dashboard'))) {
        matchRoute = true;
    } else {
        matchRoute = link !== '/' && pathname.includes(link);
    }

    const handleLogoutAction = () => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('b-user');
        navigate(`/auth/login`);
    }


    return (
        <li className={`h-[59px] leading-[59px] relative ${navStatus ? '' : '!h-[72px] !leading-[72px]'}`}>
            {matchRoute && <span className={`absolute w-[4px] h-[40px] start-0 top-[10px] bg-center bg-no-repeat block ${navStatus ? '' : '!top-[16px]'}`} style={{background:"url('/assets/images/main-active.svg')"}}></span>}
            <Link to={link} className={`block h-[59px] leading-[59px] relative ${navStatus ? '' : '!h-[72px] !leading-[72px]'}`} onClick={()=>type === 'logout' && handleLogoutAction()}>
                <span className={`w-[24px] h-[24px] bg-center bg-no-repeat inline-block align-middle ms-[20px] me-[10px] transition-all ${matchRoute ? 'brightness-200' : ''} ${navStatus ? '' : '!w-[40px] !h-[40px] !bg-contain !ms-[31px]'}`} style={{background:"url('/assets/images/"+icon+"')"}}></span>
                {navStatus && <>
                    <span className={`inline-block text-[18px] font-[500] ${grey300 ? 'text-grey-300' : 'text-grey-400'} align-middle transition-all ${matchRoute ? '!text-white !font-[700]' : ''}`}>{label}</span>
                    {!disableArrow && <span className={`w-[24px] h-[24px] bg-center bg-no-repeat inline-block absolute end-[15px] top-[15px] transition-all ${matchRoute ? 'brightness-200' : ''}`} style={{background:"url('/assets/images/"+arrow+"')"}}></span>}
                </>}
            </Link>
        </li>
    )

}