import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ar',
    debug: true,
    detection: {
      order: ['path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'subdomain'],

      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,


      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], 


      cookieMinutes: 10,
      cookieDomain: 'myDomain',

      htmlTag: document.documentElement,

      cookieOptions: { path: '/', sameSite: 'strict' },

      convertDetectedLanguage: 'Iso15897',
      convertDetectedLanguage: (lng) => lng.replace('-', '_')
    }
  });


export default i18n;