import imageCompression from "browser-image-compression";

const SingleImageCompress = async (imageFile) => {

    let compressedFile;

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      }

      try {
        compressedFile = await imageCompression(imageFile, options);
    
      } catch (error) {
        console.log(error);
      }



    return compressedFile;
}

export default SingleImageCompress;