export default function SingleImageUpload({onchange,preview=null,label=false,subLabel=false,info=false,isRequird=false,inline=false,profile=false,toTop=false}){

    return (
        <div className={`${inline && 'inline-block'} ${toTop && 'mt-[-15px]'}`}>
            { label && <label htmlFor={label} className="text-grey-600 text-[16px] font-[500] leading-[24px] mb-[3px] block">{label}{isRequird && <span className="text-danger">*</span>}</label> }
            { subLabel && <div className="text-grey-400 text-[14px] font-[300] leading-[21px] mb-[3px] block">{subLabel}</div> }
            { info && <div className="text-grey-400 text-[12px] font-[300] leading-[18px] mb-[3px] block">{info}</div> }
            <div className={`w-[56px] h-[56px] bg-offWhite rounded-full cursor-pointer relative overflow-hidden`}>
                <input type="file" className={`absolute w-full h-full top-0 left-0 z-[3] !cursor-pointer opacity-0 block`} onChange={onchange} accept="image/jpeg, image/png" />
                <div className={`group-hover:opacity-20 w-[56px] h-[28px] bg-grey-400 opacity-10 absolute bottom-0 left-0 z-[1]`}></div>
                <div className={`w-[16px] h-[16px] !bg-center !bg-no-repeat absolute bottom-[5px] left-[20px] z-[2]`} style={{background: "url('/assets/images/camera.svg')"}}></div>
                {preview ? <div className={`w-[56px] h-[56px] !bg-cover relative z-0`} style={{background:"url('"+preview+"')"}}></div> : <div className={` ${profile ? 'w-[56px] h-[56px] m-0 mt-[5px]' : 'w-[40px] h-[40px] m-[8px]'} !bg-cover relative z-0`} style={{background: profile ? "url('/assets/images/profile.svg')" : "url('/assets/images/gallery.svg')"}}></div>}
            </div>
        </div>
    )
}