import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "../../components/helpers/useDocumentTitle";
import PageHeader from "../../components/shared/PageHeader";
import PageMain from "../../containers/shared/PageMain";
import apiClient from "../../components/utils/apiClient";
import MainContent from "../../containers/shared/MainContent";
import UnitBar from "../../components/property/unit/UnitBar";
import UnitCard from "../../components/property/unit/UnitCard";

const SingleProperty = () => {
    const {t,i18n} = useTranslation();
    const { id } = useParams();
    const [property, setProperty] = useState(null);
    const [units, setUnits] = useState(null);
    useDocumentTitle(property?.name);
    const navigate = useNavigate();
    const pageTitle = {title: property?.name, subTitle: property?.description};

    
    const getProperty = async () => {
        try {
          const response = await apiClient.get(`/b-properties/${id}`);
          if(response?.data) {
            setProperty(response?.data);
            setUnits(response?.data?.units?.filter(unit=> !unit?.deletedAt));
          } else {
  
          }
        } catch(err) {}
      }

    useEffect(()=>{
        getProperty();
    },[])

    const handleClickUnitCard = (UnitId) => {
      navigate(`/ar/unit/${UnitId}`);
    }

  return (
    <>
        <div>
        <PageHeader pageTitle={pageTitle} isColored />
        <PageMain>
            <div className="absolute top-0 left-0 w-full h-full bg-grey-50 z-0"></div>
            <MainContent>
            <UnitBar unitsCount={property?.numberOfUnits} unitLabel={property?.name} ButtonLink={`/ar/property/${id}/addUnit`} />
            {units !== null && Array.isArray(units) && units?.length < 1 && 
                <div className="pt-[80px] text-center">
                  <div className="inline-block w-[188px] h-[188px] !bg-contain !bg-center" style={{background: "url('/assets/images/main-property-icon.svg')"}}></div>
                  <div className="w-full text-center text-grey-900 text-[24px] font-[500] mb-[25px]">لم تقم بإضافة اي وحدات حتي الأن</div>
                  <Link className="inline-block min-w-[320px] h-[48px] leading-[48px] rounded-[8px] text-white bg-grey-800 text-[18px] font-[600]" to={`/ar/property/${id}/addUnit`}>إنشاء وحدة جديدة</Link>
                </div> }
            {units !== null && Array.isArray(units) && units?.length > 0 && 
              <div className="pt-[25px] flex gap-[32px] flex-wrap">
                {units?.map(((unit,index)=>{
                  return <UnitCard key={`property-${index}`} name={unit?.name} city={unit?.addressDetails?.city} onClick={()=>handleClickUnitCard(unit?._id)} />
                }))}
              </div> }
            </MainContent>
        </PageMain>
        </div>
    </>
  );
};

export default SingleProperty;
