import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OtpInput } from 'reactjs-otp-input';

export default function OTPInputField({label,phone=false,type='text',value,name,placeholder,onChange,disabled,error,errorMessage,translateError=false,isRequird=false,onkeydown, autoComplete='off',role}){

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const {t} = useTranslation();
    

    return (
        <div className="min-h-[40px] w-[100%] mb-[10px] relative">
            { label && <label htmlFor={label} className="text-grey-900 text-[14px] font-[600] leading-[21px] mb-[3px] block">{label}{isRequird && <span className="text-danger">*</span>}</label> } 
            <OtpInput
                dir={phone ? 'ltr': ''}
                autoComplete={autoComplete}
                role={role}
                type={showPassword ? "text" : type}
                id={label}
                value={value}
                name={name}
                placeholder={'----'}
                onChange={onChange}
                onKeyDown={onkeydown}
                disabled={disabled}
                numInputs={4}
                containerStyle={{maxWidth:"268px", gap:"10px", outline:'none'}}
                inputStyle={{outline:"none",width:"100%"}}
                className={`text-grey-600 transition-[border] border-grey-300 ease-in-out h-[48px] w-full text-[16px] bg-white rounded-[8px] border ${ error ? '!border-danger' : '' } ${ disabled ? '!bg-disabled !border-disabled' : '' } ${phone ? '!text-left !pl-[127px]' : ''}`}
            />
            {phone && <><div className="absolute bg-white top-[34px] left-[15px] p-[1px_0px] h-[27px] cursor-pointer pe-[10px] border-grey-200 border-r-[1px]" dir="ltr"><img src="/assets/images/sa-flag.svg" className="inline-block align-middle me-[8px]" alt="" /><img src="/assets/images/flag-arrow.svg" className="inline-block align-middle" alt="" /></div><div className="absolute bg-white top-[36px] left-[75px] ps-[10px] text-grey-600 text-[16px]" dir="ltr">+996</div></>}
            { type == 'password' && <div 
            onClick={handleClickShowPassword}
            className={`cursor-pointer absolute end-[12px] top-[36px]`}><img
                src={showPassword ? '/assets/images/Show.svg' :'/assets/images/Hide.svg'}
                alt={''}
                width={24}
                height={24}
                className="dark:invert-[1]"
            /></div> }
            {errorMessage && <p className="text-danger text-[14px] font-[400] leading-[20px] pe-[5px] pt-[3px] mb-[0] block"><img src="/assets/images/error.svg" alt="" className="inline w-[16px] h-[16px] align-middle me-[3px]" />{translateError ? t(errorMessage) : errorMessage}</p>}
        </div>
    )
}